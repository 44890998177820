<script>
import api from "@/helpers/api-rest/api.js";
import format from 'date-fns/format';
import ResumeInvoiceModal from "./resume-invoice-modal.vue";
import ActionsModal from "@/components/modals/actions-modal.vue";
import Vue from "vue";
import Swal from "sweetalert2";
import store from '@/state/store';
import vue2Dropzone from "vue2-dropzone";
import qs from 'qs';
const initialState = () => {
    const token = localStorage.getItem('authToken');
    return {
        isModalOpen: false,
        dataUser: [],
        trackingStatusList: [],
        selectOptionsActions: [],
        collapseOpen: [],
        resumeInvoiceModalId: 'ModalResumeInvoice',
        actionModalId: 'ModalActionsFromTracking',
        actionView: undefined,
        resumeEdit: undefined,
        controlEconomicEdit: undefined,
        loading: false,
        periodic_tracking:{
          id:null,          
        },
        periodic_tracking_rows: [],
        tableKey: 0,  
        dropzoneOptions: {
          url: process.env.VUE_APP_API_HOST + '/api/v1/upload-file',
          method: 'post',
          thumbnailWidth: 150,
          maxFilesize: 50, //MB
          paramName: 'file',
          headers: {
            'Authorization': 'Bearer ' + token
          },
          addRemoveLinks: true,
          autoProcessQueue: false
        },
        periodicTrackingRow: null,
        essay: {}
    };
}

export default {
  emits: ['onClose'],
  props: {
    id: { type: String, default: "ModalPeriodicTracking" },
    isNew: { type: Boolean, default: false },
    periodicTrackingId: Number,
    essayId: { type: [Number, String] },
    tracking: {
      type: Object,
      default: () => {
        return {
          id: null,
          tracking_control_economic: [],
          periodic_tracking_row_documents: []
        };
      },
    },
  },
  components: { ResumeInvoiceModal, ActionsModal, vueDropzone: vue2Dropzone },
  data() {
      return initialState();
  },
  computed: {
    modalTitle() {
        return this.tracking?.id ? "Editar seguimiento" : "Añadir seguimiento";
    },
    submitButtonText() {
      return this.tracking?.id ? 'Guardar' : 'Añadir';
    },
  },
  methods: {
    loadDataEssay(){
        const apiEndpoint = `/api/v1/essays/${this.essayId}`;
        api.get({url:apiEndpoint})
        .then(response => {
            this.essay = response?.data?.data;
        })
        .catch(error => {
            console.error("Error loading data:", error);
        });
    },
    showElement(roleId = [], show = true, showRechazado = true, showRenuncia = true) {
      const role = store.getters['auth/role'];
      if (roleId.includes(role.id) && show && showRechazado && showRenuncia) {
        return true;
      }
      return false;
    },
    loadSelectOptionsTrackingStatus(){
        const apiEndpoint = `/api/v1/tracking-status`;
        api.get({url:apiEndpoint})
        .then(response => {
            this.trackingStatusList = response?.data?.data;
        })
        .catch(error => {
            console.error("Error al cargar los estados:", error);
        });
    },
    async loadActionsForEssay(){

      try {
        const response = await api.get({
            url: `/api/v1/essay-actions?essay_id=${this.essayId}`,
            config: {
                withLoading: true
            }  
        });
        return response?.data?.data;
      } catch (error) {
        console.error("Error al cargar las acciones:", error);
      }
      
    },
    async loadPeriodicTracking(){

      try {
        const response = await api.get({
          url: `/api/v1/periodic-trackings/${this.periodicTrackingId}`,
          config: {
              withLoading: true
          }  
        });
        const data = response?.data?.data;
        return this.wrapPeriodicTracking(data);

      } catch (error) {
        console.error("Error al cargar el seguimiento:", error);
      }
      
    },
    wrapPeriodicTracking(data){
      if (this.isNew) {
        data.id = null;
        data.periodic_tracking_rows.map((item) => {
          item.id = null;
          item.periodic_tracking_row_documents.map((item) => {
            item.id = null;
            item.periodic_tracking_row_id = null;
            return item;
          });
          item.tracking_control_economics.map((item) => {
            item.id = null;
            item.periodic_tracking_row_id = null;
            return item;
          });
        
          return item;
        });
        
        console.log("Periodic Tracking ", data);
        
      }
      return data;
    },
    addRowControlEconomic(periodicTrackingRow){
      periodicTrackingRow.tracking_control_economics.push({
        id: null,
        material: '',
        cost: '',
        document_path: '',
        // document_name: 'Selecciona un archivo...',
        document_name: '',
      });
    },
    deleteRowControlEconomic(periodicTrackingRow, key){
      periodicTrackingRow.tracking_control_economics.splice(key, 1);
    },
    onFileChange(event, periodicTrackingRow, type) {
      const file = event.target.files[0];
      this.uploadFile(file, 'trackings')
      .then((response) => {
        const responseFile = response?.data?.data;
        switch (type) {
          case 'graphic':
            periodicTrackingRow.document_path_graphic = responseFile.path;
            periodicTrackingRow.document_name_graphic = file.name;
            break;
          case 'technical':
            periodicTrackingRow.document_path_technical = responseFile.path;
            periodicTrackingRow.document_name_technical = file.name;
            break;
          case 'formative':
            periodicTrackingRow.document_path_formative = responseFile.path;
            periodicTrackingRow.document_name_formative = file.name;
            break;
        }
      })
      .catch((error) => {
        console.error("Error al cargar el archivo:", error);
      });
    },
    onFileChangeControl(event, trackingControlEconomic){
      const file = event.target.files[0];
      this.uploadFile(file, 'invoices')
      .then((response) => {
        const responseFile = response?.data?.data;
        console.log(responseFile);
        
        trackingControlEconomic.document_name = file.name;
        trackingControlEconomic.document_path = responseFile.path;
      })
      .catch((error) => {
        console.error("Error al cargar el archivo:", error);
      });
    },
    uploadFile(file, folder){
      const formData = new FormData();
      formData.set("file", file);
      formData.append("path", folder);
      const config = { headers: { "content-type": "multipart/form-data" } };

      const apiEndpoint = `/api/v1/upload-file`;
      return api.post({ url: apiEndpoint, data: formData, config })
    },
    toggleCollapse(index) {
      this.$set(this.collapseOpen, index, !this.collapseOpen[index]);
    },
    submitForm() {
        // if(this.center?.id){
        //     return this.updateCenter();
        // }
        // return this.createCenter();
    },
    cancelForm() {
        this.$bvModal.hide(this.id);
    },
    editTracking(resume) {
      this.resumeEdit = resume;
      this.$bvModal.show(this.resumeInvoiceModalId);
    },
    addResumeInvoice(control){
      if (!control?.id) {
        Swal.fire({
          icon: 'warning',
          html: "Para justificar el gasto, primero debes guardar el control económico",
          timer: 2500,
          showConfirmButton: false,
        });
        return;
      }
      this.controlEconomicEdit = control;
      this.resumeEdit = {id: null, essay_id: this.essayId};
      this.$bvModal.show(this.resumeInvoiceModalId);
    },
    editResumeInvoice(resume, control){
      this.controlEconomicEdit = control;
      this.resumeEdit = resume;
      this.resumeEdit.essay_id = this.essayId;      
      this.$bvModal.show(this.resumeInvoiceModalId);
    },
    async loadData(){
      this.loadSelectOptionsTrackingStatus();
      this.loadDataEssay();
      let periodic_tracking = null;
      if (this.periodicTrackingId) {
          periodic_tracking = await this.loadPeriodicTracking();
          this.periodic_tracking = periodic_tracking;
      }
      
      const actions = await this.loadActionsForEssay();
      this.wrapPeriodicTrackingRowsWithActions(periodic_tracking?.periodic_tracking_rows, actions);

    },
    wrapPeriodicTrackingRowsWithActions(periodic_tracking_rows, actions){
      this.periodic_tracking_rows = [];
      actions.forEach(action => {
        action.init_date = format(new Date(action.init_date), 'dd/MM/yyyy');
        action.end_date = format(new Date(action.end_date), 'dd/MM/yyyy');
        let periodic_tracking_row = periodic_tracking_rows?.find(row => row?.essay_action_id === action.id);

        if (!periodic_tracking_row) {
          periodic_tracking_row = {
            id: null,
            essay_action_id:null,
            periodic_tracking_id:null,
            tracking_status_id:3,
            created_by_id:null,
            updated_by_id:null,
            contingency:null,
            observation:null,
            tracking_control_economics:[],
            periodic_tracking_row_documents:[]
          }
        }

        periodic_tracking_row.essay_action_id = action.id;
        periodic_tracking_row.essay_action = action;

        this.periodic_tracking_rows.push(periodic_tracking_row);

      });
    },
    onClose(){
      this.resetModal();
      this.$emit("onClose");
    },
    resetModal() {
      Object.assign(this.$data, initialState());
    },    
    async savePeriodicTracking(){
      const response = await api.post({
          url: `/api/v1/periodic-trackings`,
          data:{
            essay_id: this.essayId
          }         
      });
      this.periodic_tracking = response?.data?.data;
    },
    changeStatus(periodic_tracking_row, status_id, key){
      periodic_tracking_row.tracking_status_id = status_id;
      if(periodic_tracking_row.id){
        this.submitPeriodicTrackingRow(periodic_tracking_row, key);
      }
    },
    async submitPeriodicTrackingRow(periodicTrackingRow, key){
      this.periodicTrackingRow = periodicTrackingRow;
      if(!this.validateHasQueuedFiles(key)){
        this.saveTrackingRow();
        return;
      }

      this.loadDropzoneFilesGraphics(key);
      
    },
    addAction(){
        this.actionView = {id: null, education_activities: [], economic_forecats: [], essay_id: this.essayId};
        this.$bvModal.show(this.actionModalId);
    },
    successAddAction(){
        this.loadData();
    },
    successAddResume(resume){
      this.controlEconomicEdit.resume_invoice = resume;
    },
    validateHasQueuedFiles(key){
      if(this.$refs['myVueDropzone' + key][0].getQueuedFiles().length == 0 && this.$refs['myVueDropzone2' + key][0].getQueuedFiles().length == 0 && this.$refs['myVueDropzone3' + key][0].getQueuedFiles().length == 0){
        return false;
      }
      return true;
    },
    async saveTrackingRow(){
      try{
        this.loading = true;
        if(!this.periodic_tracking?.id){
          await this.savePeriodicTracking();
        }        
        const response = await api.save({
            url: `/api/v1/periodic-tracking/${this.periodic_tracking?.id}/periodic-tracking-rows`,
            id: this.periodicTrackingRow?.id,
            data: this.periodicTrackingRow             
        });
        
        Object.assign(this.periodicTrackingRow, response?.data?.data);

        Vue.swal({
            position: "top-center",
            icon: "success",
            title: "Seguimiento guardado exitosamente",
            showConfirmButton: false,
            timer: 2000
        });

      } catch (error) {
        console.error("Error al guardar el seguimiento:", error);
      }
      
      this.loading = false;
    },
    uploadDropzoneFinish(file, response, periodicTrackingRow, type){      
      periodicTrackingRow.periodic_tracking_row_documents.push({
        id: null,
        document_name: file.name,
        document_path: response?.data?.path,
        slug_type: type
      });
      
    },
    uploadDropzoneQueueComplete(key, type){
      // if(!this.validateHasQueuedFiles(key)){
      //   this.saveTrackingRow();
      // }
      switch (type) {
        case 'graphic':
          this.loadDropzoneFilesTechnical(key);
          break;
        case 'technical':
          this.loadDropzoneFilesFormative(key);
          break;
        case 'formative':
          this.saveTrackingRow();
          break;
        default:
          break;
      }
      
    },
    loadDropzoneFilesGraphics(key){
      if (this.$refs['myVueDropzone' + key][0].getQueuedFiles().length == 0) {
        this.loadDropzoneFilesTechnical(key);
        return
      }
      this.$refs['myVueDropzone' + key][0].processQueue();
    },
    loadDropzoneFilesTechnical(key){
      if (this.$refs['myVueDropzone2' + key][0].getQueuedFiles().length == 0) {
        this.loadDropzoneFilesFormative(key);
        return
      }
      this.$refs['myVueDropzone2' + key][0].processQueue();
    },
    loadDropzoneFilesFormative(key){
      if (this.$refs['myVueDropzone3' + key][0].getQueuedFiles().length == 0) {
        this.saveTrackingRow();
        return
      }
      this.$refs['myVueDropzone3' + key][0].processQueue();
    },
    downloadFiles(fileName, folderID){
        const params = {
            'fileName': fileName,
            'folderID': folderID,
        };

        const queryString = qs.stringify(params);
        const baseUrl = process.env.VUE_APP_API_HOST;
        const url = `${baseUrl}/download-drive?${queryString}`;
        window.open(url, '_blank');
    },
  },
};
</script>
<template>
  <div>
    <ResumeInvoiceModal :id="resumeInvoiceModalId" :resume="resumeEdit" :control_economic="controlEconomicEdit" @success="successAddResume"/>
    <ActionsModal :id="actionModalId" :action="actionView" @success="successAddAction"/>
    <b-modal :id="id" :title="modalTitle" @shown="loadData" @hidden="onClose" scrollable :size="'extra-xl'">
      <template #default="{ }">
        <form @submit.prevent="submitForm" enctype="multipart/form-data">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Acciones</h4>
              <b-alert v-if="essay?.essay_status_id != 7 && essay?.essay_status_id != 4 && essay?.essay_status_id != 6" show>
                <b-icon icon="exclamation-circle-fill" class="mr-2" variant="info"></b-icon> 
                  Si quieres añadir una acción no prevista para complementarla con la redacción, pulsa <b>
                  <a href="javascript:void(0);" @click="addAction()">aquí</a>
                </b> 
              </b-alert>
              <b-card no-body class="mb-1 shadow-lg mt-4" v-for="(periodic_tracking_row, i) in periodic_tracking_rows" :key="i">
                <b-card-header header-tag="header" class="p-3 sticky-header" role="tab">
                  <h6 class="m-0 d-flex justify-content-between align-items-center">
                    <div class="form-inline">
                      <span>{{ periodic_tracking_row?.essay_action?.name }} <small class="font-italic ml-3">({{ periodic_tracking_row?.essay_action?.init_date }} - {{ periodic_tracking_row?.essay_action?.end_date }})</small></span>                      
                    </div>
                    <div class="text-right">
                      <button
                        v-for="(status, j) in trackingStatusList"
                        :key="j"
                        type="button"
                        :disabled="!periodic_tracking_row?.id || showElement([1,3], essay?.essay_status_id == 7, essay?.essay_status_id == 4, essay?.essay_status_id == 6)"
                        @click="changeStatus(periodic_tracking_row, status?.id, i)"
                        class="btn btn-sm mr-1"
                        :class="{
                          'btn-light': periodic_tracking_row?.tracking_status_id != status?.id,
                          ['btn-' + status.badge_color]: periodic_tracking_row?.tracking_status_id == status?.id
                        }"
                      >{{status?.description}}</button>
                      <!-- <b-button size="sm" pill variant="info" class="mr-4">Justificante</b-button> -->
                      <b-icon style="margin-right: 10px; cursor: pointer" :icon="collapseOpen['collapse-' + i] ? 'chevron-down' : 'chevron-up'" @click="toggleCollapse('collapse-' + i)"  v-b-toggle="'collapse-' + i"></b-icon>
                    </div>
                  </h6>
                </b-card-header>
                <b-collapse :id="'collapse-' + i" accordion="variation-accordion" role="tabpanel">
                  <b-card-body>
                    <b-card-text>
                      <div class="row">
                        <div class="col-lg-3">
                            <h6>Nombre</h6>
                            <p class="text-muted">
                                {{ periodic_tracking_row?.essay_action?.name }}
                            </p>
                        </div>
                        <div class="col-lg-3">
                          <h6>Responsable</h6>
                          <p class="text-muted">
                              {{ periodic_tracking_row?.essay_action?.responsables }}
                          </p>
                        </div>
                        <div class="col-lg-3">
                          <h6>Fecha inicial</h6>
                          <p class="text-muted">
                              {{ periodic_tracking_row?.essay_action?.init_date }}
                          </p>
                        </div>
                        <div class="col-lg-3">
                          <h6>Fecha final</h6>
                          <p class="text-muted">
                              {{ periodic_tracking_row?.essay_action?.end_date }}
                          </p>
                        </div>
                      </div>
                      <div class="row mt-2">
                        <div class="col-lg-12 mt-4">
                          <h6>Descripción</h6>
                          <p class="text-muted">
                              {{ periodic_tracking_row?.essay_action?.descriptions }}
                          </p>
                        </div>
                      </div>
                      <div class="row mt-2">
                        <div class="col-lg-12 mt-4">
                          <h6>Plan de contingencia</h6>
                          <p class="text-muted">
                              {{ periodic_tracking_row?.essay_action?.contingency_plan }}
                          </p>
                        </div>
                      </div>
                      <div class="row mt-2">
                        <div class="col-lg-12 mt-4">
                          <h6>Riesgos</h6>
                          <p class="text-muted">
                              {{ periodic_tracking_row?.essay_action?.risks }}
                          </p>
                        </div>
                      </div>
                      <div class="row mt-3">
                        <div class="col-lg-12">
                          <div class="form-group">
                            <label for="observationTarget">Observaciones</label>
                            <div v-if="essay?.essay_status_id == 7 || essay?.essay_status_id == 4 || essay?.essay_status_id == 6">
                              <p class="text-muted">
                                  {{ periodic_tracking_row?.observation }}
                              </p>
                            </div>
                            <textarea v-else v-model="periodic_tracking_row.observation" class="form-control" id="observationTarget" rows="3" placeholder="Observaciones"></textarea>
                          </div>
                        </div>
                      </div>
                      <div class="row mt-3">
                        <div class="col-lg-12">
                          <label for="fileGraphic">Documentos gráficos </label><br>
                          <table class="table table-bordered table-sticky-header">
                            <thead>
                              <tr>
                                <th scope="col">Nombre documento</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(document, k) in periodic_tracking_row.periodic_tracking_row_documents.filter(doc => doc.slug_type === 'graphic')" :key="k" class="">
                                <td>
                                  <div class="form-group">
                                    <p>{{ document.document_name }}</p>                                        
                                  </div>
                                </td>
                                
                                <td class="text-center">
                                  <a href="javascript:void(0);" class="mr-3 text-secondary" @click.prevent="downloadFiles(document.document_name, essay.folder_graphics_id)">
                                    <i class="mdi mdi-file-download-outline font-size-18"></i>
                                  </a>  
                                  <!-- <a
                                      href="javascript:void(0);"
                                      class="text-danger"
                                      v-b-tooltip.hover
                                      title="Eliminar"
                                      @click="deleteRowActionForecast(i, k)"
                                  >
                                      <i class="mdi mdi-trash-can font-size-18"></i>
                                  </a> -->
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <div v-if="showElement([3], essay?.essay_status_id != 7, essay?.essay_status_id != 4, essay?.essay_status_id != 6)">
                            <small>(Solo se permiten extensiones: .jpg, .jpeg, .png, .zip, .mp4, .avi | Tamaño máximo 50MB)</small>
                            <vue-dropzone
                              :id="'dropzone-' + i"
                              :ref="'myVueDropzone' + i"
                              :use-custom-slot="true"
                              :options="dropzoneOptions"
                              @vdropzone-success="(file, response) => uploadDropzoneFinish(file, response, periodic_tracking_row,'graphic', i)"
                              @vdropzone-queue-complete="() => uploadDropzoneQueueComplete(i, 'graphic')"
                            >
                              <div class="dropzone-custom-content">
                                <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                                <h4>Arrastre y suelte los archivos aquí o haga clic para subirlo</h4>
                              </div>
                            </vue-dropzone>
                          </div>
                        </div>
                        <div class="col-lg-12 mt-3">
                          <label for="fileGraphic">Documentos técnicos</label><br>
                          <table class="table table-bordered table-sticky-header">
                            <thead>
                              <tr>
                                <th scope="col">Nombre documento</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(document, k) in periodic_tracking_row.periodic_tracking_row_documents.filter(doc => doc.slug_type === 'technical')" :key="k" class="">
                                <td>
                                  <div class="form-group">
                                    <p>{{ document.document_name }}</p>                                        
                                  </div>
                                </td>
                                
                                <td class="text-center">
                                  <a href="javascript:void(0);" class="mr-3 text-secondary" @click.prevent="downloadFiles(document.document_name, essay.folder_technicials_id)">
                                    <i class="mdi mdi-file-download-outline font-size-18"></i>
                                  </a> 
                                  <!-- <a
                                      href="javascript:void(0);"
                                      class="text-danger"
                                      v-b-tooltip.hover
                                      title="Eliminar"
                                      @click="deleteRowActionForecast(i, k)"
                                  >
                                      <i class="mdi mdi-trash-can font-size-18"></i>
                                  </a> -->
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <div v-if="showElement([3], essay?.essay_status_id != 7, essay?.essay_status_id != 4, essay?.essay_status_id != 6)">
                            <small>(Solo se permiten extensiones: .pdf, .zip | Tamaño máximo 10MB)</small>
                            <vue-dropzone
                              :id="'dropzone2-' + i"
                              :ref="'myVueDropzone2' + i"
                              :use-custom-slot="true"
                              :options="dropzoneOptions"
                              @vdropzone-success="(file, response) => uploadDropzoneFinish(file, response, periodic_tracking_row,'technical', i)"
                              @vdropzone-queue-complete="() => uploadDropzoneQueueComplete(i, 'technical')"
                            >
                              <div class="dropzone-custom-content">
                                <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                                <h4>Arrastre y suelte los archivos aquí o haga clic para subirlo</h4>
                              </div>
                            </vue-dropzone>
                          </div>
                        </div>
                        <div class="col-lg-12 mt-3">
                          <label for="fileGraphic">Documentos formativos</label><br>
                          <table class="table table-bordered table-sticky-header">
                            <thead>
                              <tr>
                                <th scope="col">Nombre documento</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(document, k) in periodic_tracking_row.periodic_tracking_row_documents.filter(doc => doc.slug_type === 'formative')" :key="k" class="">
                                <td>
                                  <div class="form-group">
                                    <p>{{ document.document_name }}</p>                                        
                                  </div>
                                </td>                                
                                <td class="text-center">
                                  <a href="javascript:void(0);" class="mr-3 text-secondary" @click.prevent="downloadFiles(document.document_name, essay.folder_documents_id)">
                                    <i class="mdi mdi-file-download-outline font-size-18"></i>
                                  </a> 
                                  <!-- <a
                                      href="javascript:void(0);"
                                      class="text-danger"
                                      v-b-tooltip.hover
                                      title="Eliminar"
                                      @click="deleteRowActionForecast(i, k)"
                                  >
                                      <i class="mdi mdi-trash-can font-size-18"></i>
                                  </a> -->
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <div v-if="showElement([3], essay?.essay_status_id != 7, essay?.essay_status_id != 4, essay?.essay_status_id != 6)">
                            <small>(Solo se permiten extensiones: .pdf, .zip | Tamaño máximo 10MB)</small>
                            <vue-dropzone
                              :id="'dropzone3-' + i"
                              :ref="'myVueDropzone3' + i"
                              :use-custom-slot="true"
                              :options="dropzoneOptions"
                              @vdropzone-success="(file, response) => uploadDropzoneFinish(file, response, periodic_tracking_row,'formative', i)"
                              @vdropzone-queue-complete="() => uploadDropzoneQueueComplete(i, 'formative')"
                            >
                              <div class="dropzone-custom-content">
                                <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                                <h4>Arrastre y suelte los archivos aquí o haga clic para subirlo</h4>
                              </div>
                            </vue-dropzone>
                          </div>
                        </div>
                      </div>
                      <div class="row mt-3">
                        <div class="col-lg-12">
                          <label>Control económico</label>
                          <table class="table table-bordered table-sticky-header" :key="tableKey">
                            <thead>
                              <tr>
                                <th scope="col">Material</th>
                                <th scope="col">Coste (€)</th>
                                <th scope="col">Factura</th>
                                <th scope="col">Justificación</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(control, i) in periodic_tracking_row?.tracking_control_economics" :key="i" class="">
                                <td>
                                  <div class="form-group">
                                    <div v-if="essay?.essay_status_id == 7 || essay?.essay_status_id == 4 ||essay?.essay_status_id == 6">
                                      <p class="text-muted">
                                          {{ control?.material }}
                                      </p>
                                    </div>
                                    <input v-else type="text" v-model="control.material" class="form-control" placeholder="Material" />                                          
                                  </div>
                                </td>
                                <td>
                                  <div class="form-group">
                                    <div v-if="essay?.essay_status_id == 7 || essay?.essay_status_id == 4 || essay?.essay_status_id == 6">
                                      <p class="text-muted">
                                          {{ control?.cost }}
                                      </p>
                                    </div>
                                    <input v-else type="text" v-model="control.cost" class="form-control" placeholder="Coste" />                                     
                                  </div>
                                </td>
                                <td>
                                  <div v-if="essay?.essay_status_id == 7 || essay?.essay_status_id == 4 || essay?.essay_status_id == 6">
                                    <b-button variant="info" @click.prevent="downloadFiles(control?.document_name, essay?.folder_economics_id)"><i class="fas fa-file-download mr-2"></i> Descargar</b-button>
                                  </div>
                                  <div v-else class="form-group">
                                    <div class="custom-file">
                                      <input id="inputFileInvoice" type="file" name="fileControl" class="custom-file-input" @change="event => onFileChangeControl(event, control)" />
                                      <label class="custom-file-label" for="inputFileInvoice">{{ control?.document_name || 'Selecciona un archivo...' }}</label>
                                    </div>                                      
                                  </div>
                                </td>
                                <td class="text-center">
                                  <a v-if="!control.resume_invoice && showElement([3], essay?.essay_status_id != 7, essay?.essay_status_id != 4, essay?.essay_status_id != 6)" href="javascript:void(0);" class="text-success" @click="addResumeInvoice(control)">
                                    <i class="mdi mdi-plus-circle font-size-18"></i> Añadir 
                                  </a>
                                  <a v-else-if="control.resume_invoice && showElement([3], essay?.essay_status_id != 7, essay?.essay_status_id != 4, essay?.essay_status_id != 6)" href="javascript:void(0);" class="text-secondary" @click="editResumeInvoice(control.resume_invoice, control)">
                                    <i class="mdi mdi-pencil-circle font-size-18"></i> Editar 
                                  </a>
                                </td>
                                <td v-if="showElement([1,3], essay?.essay_status_id != 7, essay?.essay_status_id != 4, essay?.essay_status_id != 6)" class="text-center">
                                  <a
                                      href="javascript:void(0);"
                                      class="text-danger"
                                      v-b-tooltip.hover
                                      title="Eliminar"
                                      @click="deleteRowControlEconomic(periodic_tracking_row,i)"
                                  >
                                      <i class="mdi mdi-trash-can font-size-18"></i>
                                  </a>
                                </td>
                              </tr>
                            </tbody>
                            <tfoot>
                              <tr v-if="showElement([1,3], essay?.essay_status_id != 7, essay?.essay_status_id != 4, essay?.essay_status_id != 6)">
                                <td colspan="4" class="text-right">
                                    <a href="javascript:void(0);" class="text-success" @click="addRowControlEconomic(periodic_tracking_row)">
                                      <i class="mdi mdi-plus-circle font-size-18"></i> Agregar control económico
                                    </a>
                                </td>
                              </tr>
                            </tfoot>
                          </table>
                        </div>
                      </div>
                    </b-card-text>
                  </b-card-body>
                  <b-card-footer class="text-right">
                    <b-button v-if="showElement([1,3], essay?.essay_status_id != 7, essay?.essay_status_id != 4, essay?.essay_status_id != 6)" variant="success" @click="submitPeriodicTrackingRow(periodic_tracking_row, i)" :disabled="loading">
                      <!-- <b-button variant="success" @click="submitPeriodicTrackingRow(periodic_tracking_row, i)" :disabled="loading"> -->
                      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="loading"></span>
                      Guardar
                    </b-button>
                  </b-card-footer>
                </b-collapse>
              </b-card>
            </div>
          </div>
        </form>
      </template>
      <template #modal-footer="{  }">
        <b-button @click="cancelForm()">Cerrar</b-button>
      </template>
    </b-modal>
  </div>
</template>