<script>
import api from "@/helpers/api-rest/api.js";
import store from '@/state/store';
import qs from 'qs';
import { required, requiredIf, email } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import { errorMessages } from "@/helpers/validations/errorMessages";

const isPdf = (value) => {
  return typeof value === "string" && value.toLowerCase().endsWith(".pdf");
};

export default {
  props: {
    id: { type: String, default: "ModalAccessionCenter" },
    essayId: { type: [Number, String] },
    centerId: { type: Number },
    accesion_center: {
      type: Object,
      default: () => {
        return {
          id: null,
          document_name: null,
          // accession_center_professorates: [],
        };
      },
    },
  },
  data() {
    return {
      isModalOpen: false,
      dataUser: [],
      selectOptionsTrackingStatus: [],
      selectOptionsActions: [],
      collapseOpen: [],
      loading: false,
      selectOptionsCenter: [],
      dataEssay: {},
      errorMessages,
      validateFile: false,
      selectedCourses: []
    };
  },
  computed: {
    modalTitle() {
        return this.accesion_center?.id ? "Editar adhesión" : "Añadir adhesión";
    },
    submitButtonText() {
      return this.accesion_center?.id ? 'Guardar' : 'Añadir';
    },
  },
  created() {
    this.loadSelectOptionsCenter();
  },
  validations() {
    return {
      accesion_center: {
        reason: {required},
        center_id: {required: requiredIf(function () {
          return this.dataEssay?.project?.project_type_id == 1;
        })},
        courses: {
          $each: {
            id: {required: requiredIf(function () {
              return this.dataEssay?.project?.project_type_id == 1;
            })}
          }
        },
        center_code: { required: requiredIf(function () {
          return this.dataEssay?.project?.project_type_id !== 1;
        }) },
        city: { required: requiredIf(function () {
          return this.dataEssay?.project?.project_type_id !== 1;
        }) },
        cp: { required: requiredIf(function () {
          return this.dataEssay?.project?.project_type_id !== 1;
        }) },
        director: { required: requiredIf(function () {
          return this.dataEssay?.project?.project_type_id !== 1;
        }) },
        phone: { required: requiredIf(function () {
          return this.dataEssay?.project?.project_type_id !== 1;
        }) },
        province: { required: requiredIf(function () {
          return this.dataEssay?.project?.project_type_id !== 1;
        }) },
        name: { required: requiredIf(function () {
          return this.dataEssay?.project?.project_type_id !== 1;
        }) },
        cif: { required: requiredIf(function () {
          return this.dataEssay?.project?.project_type_id !== 1;
        }) },
        address: { required: requiredIf(function () {
          return this.dataEssay?.project?.project_type_id !== 1;
        }) },
        responsable: { required: requiredIf(function () {
          return this.dataEssay?.project?.project_type_id !== 1;
        }) },
        email: { required: requiredIf(function () {
          return this.dataEssay?.project?.project_type_id !== 1;
        }), email },
        accession_center_professorates: {
          $each: {
            name: {required},
            department: {required},
            dni: {required},
            email: {required, email}
          } 
        },
        document_name: this.validateFile ? { required, isPdf } : {}
      }
    };
  },
  methods: {
    loadCourses(){
      const apiEndpoint = `/api/v1/centers/${this.accesion_center?.center_id}`;
      // const apiEndpoint = `/api/v1/courses?center_id=${this.essay?.essay_coordinator?.center_id}`;
      api.get({url:apiEndpoint})
        .then(response => {
          this.selectedCourses = response?.data?.data?.courses;
        })
        .catch(error => {
          console.error("Error loading data:", error);
        });
    },
    formatDate(d = new Date()) {
      return `${String(d.getDate()).padStart(2,'0')}${String(d.getMonth()+1).padStart(2,'0')}${d.getFullYear()}${String(d.getHours()).padStart(2,'0')}${String(d.getMinutes()).padStart(2,'0')}`;
    },
    loadDataEssay(){
      const apiEndpoint = `/api/v1/essays/${this.essayId}`;
      api.get({url:apiEndpoint})
        .then(response => {
          const data = response?.data?.data;
          this.dataEssay = data;                   
        })
        .catch(error => {
          console.error("Error loading data:", error);
        });
    },
    loadSelectOptionsCenter(){
      const apiEndpoint = `/api/v1/centers`;
      api.get({url:apiEndpoint})
      .then(response => {
          this.selectOptionsCenter = response?.data?.data;
      })
      .catch(error => {
          console.error("Error al cargar la lista:", error);
      });
    },
    showElement(roleId = [], show = true, showRechazado = true, showRenuncia = true) {
      const role = store.getters['auth/role'];
      if (roleId.includes(role.id) && show && showRechazado && showRenuncia) {
        return true;
      }
      return false;
    },
    onFileChange(event){
      this.file = event.target.files[0];

      if (this.file.type !== "application/pdf") {
        this.accesion_center.document_name = "";
        this.$v.accesion_center.document_name.$touch();
        return;
      }

      this.$v.accesion_center.document_name.$reset();

      this.accesion_center.document_name = this.formatDate() + '-' + this.file?.name;
      this.uploadFile(this.file, 'accession')
      .then((response) => {
        const file = response?.data?.data;
        this.accesion_center.document_path = file.path;
      })
      .catch((error) => {
        console.error("Error al cargar el archivo:", error);
      });
    },
    uploadFile(file, folder){
      const formData = new FormData();
      formData.set("file", file);
      formData.append("path", folder);
      const config = { headers: { "content-type": "multipart/form-data" } };

      const apiEndpoint = `/api/v1/upload-file`;
      return api.post({ url: apiEndpoint, data: formData, config })
    },
    downloadFileAnexoIV(){
      const params = {
        'accesion_center': this.accesion_center.accession_center_professorates,
        'essay_id': this.essayId,
        'accession': true,
        'center_id': this.accesion_center.center_id
      };
      const queryString = qs.stringify(params);
      const baseUrl = process.env.VUE_APP_API_HOST;
      const url = `${baseUrl}/anexo4?${queryString}`;      
      window.open(url, '_blank');
    },
    downloadFiles(fileName, folderID){
        const params = {
            'fileName': fileName,
            'folderID': folderID,
        };

        const queryString = qs.stringify(params);
        const baseUrl = process.env.VUE_APP_API_HOST;
        const url = `${baseUrl}/download-drive?${queryString}`;
        window.open(url, '_blank');
    },
    logValidationErrors() {
      const errors = [];

      const checkErrors = (validation, path = '') => {
        for (const field in validation) {
          if (validation[field]?.$invalid) {
            errors.push(`${path}${field} es inválido`);
          }

          // Si es un objeto con más validaciones dentro, recursión
          if (typeof validation[field] === 'object' && validation[field] !== null) {
            checkErrors(validation[field], `${path}${field}.`);
          }
        }
      };

      checkErrors(this.$v.accesion_center);

      console.log("Errores de validación:", errors);
    },
    createAccessionCenter() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.logValidationErrors();
        this.warningMsgValidation();
        return;
      }
      this.loading = true;
      const apiEndpoint = `/api/v1/accession-centers`;
      this.accesion_center.essay_id = this.essayId;
      api.post({url:apiEndpoint, data:this.accesion_center})
      .then(response => {
          this.loading = false;
          const newAccession = response?.data?.data;
          this.$emit('success', newAccession);
          this.$bvModal.hide(this.id);
      })
      .catch(error => {
          this.loading = false;
          console.error("Error al crear adhesion de empresa:", error);
      });
    },

    updateAccessionCenter() {
      this.$v.$touch();
      if (this.$v.$invalid) {
          this.warningMsgValidation();
          return;
      }
      this.loading = true;
      const apiEndpoint = `/api/v1/accession-centers`;
      api.put({url:apiEndpoint, data:this.accesion_center, id:this.accesion_center.id})
      .then(response => {
          this.loading = false;
          const updatedAccession = response?.data?.data;
          this.$emit('success', updatedAccession);
          this.$bvModal.hide(this.id);
      })
      .catch(error => {
          this.loading = false;
          console.error("Error al editar adhesion de empresa:", error);
      });
    },
    changeStatusAccession(status){
      this.accesion_center.status = status;
      this.submitForm();
    },
    submitForm() {
      if(this.accesion_center?.id){
          return this.updateAccessionCenter();
      }
      return this.createAccessionCenter();
    },
    cancelForm() {
        this.$bvModal.hide(this.id);
    },
    addRowProfessorate(){
      this.accesion_center.accession_center_professorates.push({
        id: '',
        name: '',
        department: '',
        dni: '',
        email: '',
        accesion_center_id: this.accesion_center.id,
      });
    },
    deleteRowProfessorate(key){
      this.accesion_center?.accession_center_professorates.splice(key, 1);
    },
    loadData(){
      this.$v.$reset();
      if(this.accesion_center?.id){
        this.loadCourses();
      }
    },
    saveAccession(status_user) {
      this.validateFile = false; // No validar file
      this.$v.$reset();
      this.accesion_center.status_user = status_user;
      this.submitForm();
    },
    addAccession(status_user) {
      this.validateFile = true;
      this.$v.$reset();
      this.accesion_center.status_user = status_user;
      this.submitForm();
    },
    warningMsgValidation() {
      Swal.fire({
        icon: 'error',
        html: "Hay campos requeridos sin completar",
        timer: 2000,
        showConfirmButton: false,
      });
    },
    addRowCoursesAccession(){
      this.accesion_center.courses.push({
        id: '',
      });
    },
    deleteRowCourses(key){
      this.accesion_center.courses.splice(key, 1);
    },
  },
  mounted() {
    this.loadDataEssay();    
  },
};
</script>
<template>
  <div>
    <b-modal :id="id" :title="modalTitle" @shown="loadData" scrollable size="xl">
      <template #default="{ }">
        <form @submit.prevent="submitForm" enctype="multipart/form-data">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-lg-12">
                  <h6>Justificación <span style="color: red;">*</span></h6>
                  <div v-if="accesion_center?.essay?.essay_status_id == 7 || accesion_center?.essay?.essay_status_id == 4 || accesion_center?.essay?.essay_status_id == 6">
                    <p class="text-muted">
                        {{ accesion_center?.reason }}
                    </p>
                  </div>
                  <!-- <p v-if="showElement([1])" class="text-muted">
                      {{ accesion_center?.reason }}
                  </p> -->
                  <div v-else class="form-group">
                    <textarea v-model="accesion_center.reason" class="form-control" rows="3" maxlength="5000" placeholder="Introduce justificación" :class="{ 'is-invalid': $v.accesion_center.reason.$error }"></textarea>
                    <small class="text-muted">{{ accesion_center?.reason?.length }}/5000</small>
                    <div v-if="$v.accesion_center.reason.$error" class="invalid-feedback">
                      <div v-if="!$v.accesion_center.reason.required">{{ errorMessages.required }}</div>
                    </div>
                  </div>
                </div>
                <div v-if="dataEssay?.project?.project_type_id" class="col-lg-12">
                  <div class="form-group">
                    <label for="selectedCenter">Centro <span style="color: red;">*</span></label>
                    <div v-if="accesion_center?.essay?.essay_status_id == 7 || accesion_center?.essay?.essay_status_id == 4 || accesion_center?.essay?.essay_status_id == 6">
                      <p class="text-muted">
                          {{ accesion_center?.center?.name }}
                      </p>
                    </div>
                    <select v-else class="form-control" id="selectedCenter" v-model="accesion_center.center_id" @change="loadCourses" :class="{ 'is-invalid': $v.accesion_center.center_id.$error }">
                      <option :value="null">Selecciona centro</option>
                      <option v-for="option in selectOptionsCenter" :value="option.id" :key="option.id">{{ option.name }}</option>
                    </select>
                    <div v-if="$v.accesion_center.center_id.$error" class="invalid-feedback">
                      <div v-if="!$v.accesion_center.center_id.required">{{ errorMessages.required }}</div>
                    </div>
                  </div>
                </div>
                <div v-else class="col-lg-12">
                  <div class="row">
                    <div class="col-lg-4">
                      <div class="form-group">
                        <h6>Código del centro <span style="color: red;">*</span></h6>
                        <div v-if="accesion_center?.essay?.essay_status_id == 7 || accesion_center?.essay?.essay_status_id == 4 || accesion_center?.essay?.essay_status_id == 6">
                          <p class="text-muted">
                              {{ accesion_center?.center_code }}
                          </p>
                        </div>
                        <!-- <p v-if="showElement([1])" class="text-muted">
                            {{ accesion_center?.center_code }}
                        </p> -->
                        <div v-else class="form-group">
                          <input v-model="accesion_center.center_code" type="text" class="form-control" id="name" placeholder="Código" maxlength="10" :class="{ 'is-invalid': $v.accesion_center.center_code.$error }">
                          <small class="text-muted">{{ accesion_center?.center_code?.length }}/10</small>
                          <div v-if="$v.accesion_center.center_code.$error" class="invalid-feedback">
                            <div v-if="!$v.accesion_center.center_code.required">{{ errorMessages.required }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <h6>Nombre del centro <span style="color: red;">*</span></h6>
                      <div v-if="accesion_center?.essay?.essay_status_id == 7 || accesion_center?.essay?.essay_status_id == 4 || accesion_center?.essay?.essay_status_id == 6">
                        <p class="text-muted">
                            {{ accesion_center?.name }}
                        </p>
                      </div>
                      <!-- <p v-if="showElement([1])" class="text-muted">
                          {{ accesion_center?.name }}
                      </p> -->
                      <div v-else class="form-group">
                        <input v-model="accesion_center.name" type="text" class="form-control" id="name" placeholder="Nombre del centro" maxlength="200" :class="{ 'is-invalid': $v.accesion_center.name.$error }">
                        <small class="text-muted">{{ accesion_center?.name?.length }}/200</small>
                        <div v-if="$v.accesion_center.name.$error" class="invalid-feedback">
                          <div v-if="!$v.accesion_center.name.required">{{ errorMessages.required }}</div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <h6>Nombre del director/a <span style="color: red;">*</span></h6>
                      <div v-if="accesion_center?.essay?.essay_status_id == 7 || accesion_center?.essay?.essay_status_id == 4 || accesion_center?.essay?.essay_status_id == 6">
                        <p class="text-muted">
                            {{ accesion_center?.director }}
                        </p>
                      </div>
                      <!-- <p v-if="showElement([1])" class="text-muted">
                          {{ accesion_center?.director }}
                      </p> -->
                      <div v-else class="form-group">
                        <input v-model="accesion_center.director" type="text" class="form-control" id="director" maxlength="200" placeholder="Director/a" :class="{ 'is-invalid': $v.accesion_center.director.$error }">
                        <small class="text-muted">{{ accesion_center?.director?.length }}/200</small>
                        <div v-if="$v.accesion_center.director.$error" class="invalid-feedback">
                          <div v-if="!$v.accesion_center.director.required">{{ errorMessages.required }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <h6>Dirección <span style="color: red;">*</span></h6>
                    <div v-if="accesion_center?.essay?.essay_status_id == 7 || accesion_center?.essay?.essay_status_id == 4 || accesion_center?.essay?.essay_status_id == 6">
                      <p class="text-muted">
                          {{ accesion_center?.address }}
                      </p>
                    </div>
                    <!-- <p v-if="showElement([1])" class="text-muted">
                        {{ accesion_center?.address }}
                    </p> -->
                    <div v-else class="form-group">
                      <textarea v-model="accesion_center.address" class="form-control" rows="3" maxlength="5000" placeholder="Dirección" :class="{ 'is-invalid': $v.accesion_center.address.$error }"></textarea>
                      <small class="text-muted">{{ accesion_center?.address?.length }}/5000</small>
                      <div v-if="$v.accesion_center.address.$error" class="invalid-feedback">
                        <div v-if="!$v.accesion_center.address.required">{{ errorMessages.required }}</div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-4">
                      <h6>Ciudad <span style="color: red;">*</span></h6>
                      <div v-if="accesion_center?.essay?.essay_status_id == 7 || accesion_center?.essay?.essay_status_id == 4 || accesion_center?.essay?.essay_status_id == 6">
                        <p class="text-muted">
                            {{ accesion_center?.city }}
                        </p>
                      </div>
                      <!-- <p v-if="showElement([1])" class="text-muted">
                          {{ accesion_center?.city }}
                      </p> -->
                      <div v-else class="form-group">
                        <input v-model="accesion_center.city" type="text" class="form-control" id="city" maxlength="200" placeholder="Ciudad" :class="{ 'is-invalid': $v.accesion_center.city.$error }">
                        <small class="text-muted">{{ accesion_center?.city?.length }}/200</small>
                        <div v-if="$v.accesion_center.city.$error" class="invalid-feedback">
                          <div v-if="!$v.accesion_center.city.required">{{ errorMessages.required }}</div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <h6>Provincia <span style="color: red;">*</span></h6>
                      <div v-if="accesion_center?.essay?.essay_status_id == 7 || accesion_center?.essay?.essay_status_id == 4 || accesion_center?.essay?.essay_status_id == 6">
                        <p class="text-muted">
                            {{ accesion_center?.province }}
                        </p>
                      </div>
                      <!-- <p v-if="showElement([1])" class="text-muted">
                          {{ accesion_center?.province }}
                      </p> -->
                      <div v-else class="form-group">
                        <input v-model="accesion_center.province" type="text" class="form-control" id="province" placeholder="Provincia" maxlength="200" :class="{ 'is-invalid': $v.accesion_center.province.$error }">
                        <small class="text-muted">{{ accesion_center?.province?.length }}/200</small>
                        <div v-if="$v.accesion_center.province.$error" class="invalid-feedback">
                          <div v-if="!$v.accesion_center.province.required">{{ errorMessages.required }}</div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <h6>Código postal <span style="color: red;">*</span></h6>
                      <div v-if="accesion_center?.essay?.essay_status_id == 7 || accesion_center?.essay?.essay_status_id == 4 || accesion_center?.essay?.essay_status_id == 6">
                        <p class="text-muted">
                            {{ accesion_center?.cp }}
                        </p>
                      </div>
                      <!-- <p v-if="showElement([1])" class="text-muted">
                          {{ accesion_center?.cp }}
                      </p> -->
                      <div v-else class="form-group">
                        <input v-model="accesion_center.cp" type="text" class="form-control" id="cp" placeholder="Código postal" maxlength="5" :class="{ 'is-invalid': $v.accesion_center.cp.$error }">
                        <small class="text-muted">{{ accesion_center?.cp?.length }}/5</small>
                        <div v-if="$v.accesion_center.cp.$error" class="invalid-feedback">
                          <div v-if="!$v.accesion_center.cp.required">{{ errorMessages.required }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6">
                      <h6>Teléfono <span style="color: red;">*</span></h6>
                      <div v-if="accesion_center?.essay?.essay_status_id == 7 || accesion_center?.essay?.essay_status_id == 4 || accesion_center?.essay?.essay_status_id == 6">
                        <p class="text-muted">
                            {{ accesion_center?.phone }}
                        </p>
                      </div>
                      <!-- <p v-if="showElement([1])" class="text-muted">
                          {{ accesion_center?.phone }}
                      </p> -->
                      <div v-else class="form-group">
                        <input v-model="accesion_center.phone" type="text" class="form-control" id="phone" placeholder="Teléfono" maxlength="10" :class="{ 'is-invalid': $v.accesion_center.phone.$error }">
                        <small class="text-muted">{{ accesion_center?.phone?.length }}/10</small>
                        <div v-if="$v.accesion_center.phone.$error" class="invalid-feedback">
                          <div v-if="!$v.accesion_center.phone.required">{{ errorMessages.required }}</div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <h6>Email <span style="color: red;">*</span></h6>
                      <div v-if="accesion_center?.essay?.essay_status_id == 7 || accesion_center?.essay?.essay_status_id == 4 || accesion_center?.essay?.essay_status_id == 6">
                        <p class="text-muted">
                            {{ accesion_center?.email }}
                        </p>
                      </div>
                      <!-- <p v-if="showElement([1])" class="text-muted">
                          {{ accesion_center?.email }}
                      </p> -->
                      <div v-else class="form-group">
                        <input v-model="accesion_center.email" type="text" class="form-control" id="email" placeholder="Email" maxlength="200" :class="{ 'is-invalid': $v.accesion_center.email.$error }">
                        <small class="text-muted">{{ accesion_center?.email?.length }}/200</small>
                        <div v-if="$v.accesion_center.email.$error" class="invalid-feedback">
                          <span v-if="!$v.accesion_center.email.required">{{ errorMessages.required }}</span>
                          <span v-else-if="!$v.accesion_center.email.email">{{ errorMessages.email }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-12 mt-3">
                  <label>Profesorado participante</label>
                    <table class="table table-bordered table-sticky-header">
                      <thead>
                        <tr>
                          <th scope="col">Nombre</th>
                          <th scope="col">Departamento</th>
                          <th scope="col">DNI</th>
                          <th scope="col">Email</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(professorate, i) in accesion_center?.accession_center_professorates" :key="i" class="" :set="vprofessorate = $v.accesion_center.accession_center_professorates.$each[i]">
                          <td>
                            <div v-if="accesion_center?.essay?.essay_status_id == 7 || accesion_center?.essay?.essay_status_id == 4 || accesion_center?.essay?.essay_status_id == 6">
                              <p class="text-muted">
                                  {{ professorate?.name }}
                              </p>
                            </div>
                            <div v-else class="form-group">
                              <input type="text" v-model="professorate.name" class="form-control" placeholder="Nombre" maxlength="200" :class="{ 'is-invalid': vprofessorate.name.$error }"/>
                              <small class="text-muted">{{ professorate?.name?.length }}/200</small>
                              <div div v-if="vprofessorate.name.$error" class="invalid-feedback">
                                <div v-if="!vprofessorate.name.required">{{ errorMessages.required }}</div>
                              </div>                                          
                            </div>
                          </td>
                          <td>
                            <div v-if="accesion_center?.essay?.essay_status_id == 7 || accesion_center?.essay?.essay_status_id == 4 || accesion_center?.essay?.essay_status_id == 6">
                              <p class="text-muted">
                                  {{ professorate?.department }}
                              </p>
                            </div>
                            <div v-else class="form-group">
                              <input type="text" v-model="professorate.department" class="form-control" placeholder="Departamento" maxlength="200" :class="{ 'is-invalid': vprofessorate.department.$error }"/>
                              <small class="text-muted">{{ professorate?.department?.length }}/200</small>
                              <div div v-if="vprofessorate.department.$error" class="invalid-feedback"> 
                                <div v-if="!vprofessorate.department.required">{{ errorMessages.required }}</div>
                              </div>                                     
                            </div>
                          </td>
                          <td>
                            <div v-if="accesion_center?.essay?.essay_status_id == 7 || accesion_center?.essay?.essay_status_id == 4 || accesion_center?.essay?.essay_status_id == 6">
                              <p class="text-muted">
                                  {{ professorate?.dni }}
                              </p>
                            </div>
                            <div v-else class="form-group">
                              <input type="text" v-model="professorate.dni" class="form-control" placeholder="DNI" maxlength="10" :class="{ 'is-invalid': vprofessorate.dni.$error }" />
                              <small class="text-muted">{{ professorate?.dni?.length }}/10</small>
                              <div v-if="vprofessorate.dni.$error" class="invalid-feedback">
                                <div v-if="!vprofessorate.dni.required">{{ errorMessages.required }}</div>
                              </div>                                          
                            </div>
                          </td>
                          <td>
                            <div v-if="accesion_center?.essay?.essay_status_id == 7 || accesion_center?.essay?.essay_status_id == 4 || accesion_center?.essay?.essay_status_id == 6">
                              <p class="text-muted">
                                  {{ professorate?.email }}
                              </p>
                            </div>
                            <div v-else class="form-group">
                              <input type="text" v-model="professorate.email" class="form-control" placeholder="Email" :class="{ 'is-invalid': vprofessorate.email.$error }" />
                              <div v-if="vprofessorate.email.$error" class="invalid-feedback">
                                <div v-if="!vprofessorate.email.required">{{ errorMessages.required }}</div>
                                <span v-else-if="!vprofessorate.email.email">{{ errorMessages.email }}</span>
                              </div>                                          
                            </div>
                          </td>
                          <td v-if="showElement([3], accesion_center?.essay?.essay_status_id != 7, accesion_center?.essay?.essay_status_id != 4, accesion_center?.essay?.essay_status_id != 6)" class="text-center">
                              <a
                                  href="javascript:void(0);"
                                  class="text-danger"
                                  v-b-tooltip.hover
                                  title="Eliminar"
                                  @click="deleteRowProfessorate(i)"
                              >
                                  <i class="mdi mdi-trash-can font-size-18"></i>
                              </a>
                          </td>
                        </tr>
                      </tbody>
                      <tfoot v-if="showElement([3], accesion_center?.essay?.essay_status_id != 7, accesion_center?.essay?.essay_status_id != 4, accesion_center?.essay?.essay_status_id != 6)">
                        <tr>
                          <td colspan="4" class="text-right">
                              <a href="javascript:void(0);" class="text-success" @click="addRowProfessorate()">
                                <i class="mdi mdi-plus-circle font-size-18"></i> Agregar profesorado
                              </a>
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                </div>
                <div class="col-lg-12">
                  <label for="course_ids">Ciclos formativos relacionados <span style="color: red;">*</span></label>
                  <table class="table table-bordered table-sticky-header">
                    <thead>
                      <tr>
                        <th scope="col">Ciclo</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(course, i) in accesion_center.courses" :key="i" class="" :set="vcourse = $v.accesion_center.courses.$each[i]">
                      <!-- <tr v-for="(course, i) in accesion_center.courses" :key="i" class=""> -->
                        <td>
                          <div class="form-group">
                            <select class="form-control" id="course_ids" v-model="course.id" :class="{ 'is-invalid': vcourse.id.$error }">
                            <!-- <select class="form-control" id="course_ids" v-model="course.id"> -->
                              <option value="">Selecciona ciclo</option>
                              <option v-for="option in selectedCourses" :value="option.id" :key="option.id">{{ option.mode_code }} - {{ option.education }} {{ option.mode }}</option>
                            </select>
                            <div v-if="vcourse.id.$error" class="invalid-feedback">
                              <div v-if="!vcourse.id.required">{{ errorMessages.required }}</div>
                            </div>                                  
                          </div>
                        </td>
                        <td class="text-center">
                            <a
                                href="javascript:void(0);"
                                class="text-danger"
                                v-b-tooltip.hover
                                title="Eliminar"
                                @click="deleteRowCourses(i)"
                            >
                                <i class="mdi mdi-trash-can font-size-18"></i>
                            </a>
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colspan="4" class="text-right">
                            <a href="javascript:void(0);" class="text-success" @click="addRowCoursesAccession()">
                              <i class="mdi mdi-plus-circle font-size-18"></i> Agregar ciclo
                            </a>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
              <div v-if="accesion_center?.essay?.essay_status_id != 7 && accesion_center?.essay?.essay_status_id != 4 && accesion_center?.essay?.essay_status_id != 6" class="row mt-4">
                <div class="col-lg-12">
                  <b-alert show variant="warning">
                    <strong>Observaciones</strong><br>
                    <ul>
                      <li>Antes de adjuntar el <strong>Anexo IV</strong>, primero debes generarlo <a href="javascript:void(0);" @click.prevent="downloadFileAnexoIV()">aquí</a></li>
                      <li>Si has completado los dos campos anteriores, también aparecerán en el <strong>Anexo IV</strong></li>
                    </ul>
                  </b-alert>
                </div>
                <div v-if="showElement([3], accesion_center?.essay?.essay_status_id != 7, accesion_center?.essay?.essay_status_id != 4, accesion_center?.essay?.essay_status_id != 6)" class="col-lg-6 mt-3">
                  <h6>Adjuntar Anexo IV <span class="text-danger">*</span></h6>
                  <div class="form-group">
                    <div class="custom-file">
                      <input id="inputFile" type="file" name="file" class="custom-file-input" @change="onFileChange" :class="{ 'is-invalid': $v.accesion_center.document_name.$error }" accept="application/pdf"/>
                      <label class="custom-file-label" for="inputFile">{{ accesion_center.document_name || 'Seleccione un archivo' }}</label>
                      <div v-if="$v.accesion_center.document_name.$error" class="invalid-feedback">
                        <div v-if="!$v.accesion_center.document_name.isPdf">Solo se permiten archivos PDF.</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="row mt-3">
                <div class="col-lg-12 mt-3">
                  <h6>Anexo VI</h6>
                  <b-button variant="info" @click.prevent="downloadFiles(accesion_center?.document_name, accesion_center?.essay?.folder_documents_id)"><i class="fas fa-file-download mr-2"></i> Descargar</b-button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </template>
      <template #modal-footer="{  }">
        <div class="d-flex justify-content-between w-100">
          <b-button v-if="showElement([3], accesion_center?.essay?.essay_status_id != 7, accesion_center?.essay?.essay_status_id != 4, accesion_center?.essay?.essay_status_id != 6)" variant="primary" @click="saveAccession('editando')" :disabled="loading"><span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true" v-if="loading"></span>Guardar adhesión</b-button>
          <div>
            <b-button v-if="showElement([3], accesion_center?.essay?.essay_status_id != 7, accesion_center?.essay?.essay_status_id != 4, accesion_center?.essay?.essay_status_id != 6)" class="mr-2" variant="success" @click="addAccession('finalizado')" :disabled="loading"><span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true" v-if="loading"></span>Finalizar adhesión</b-button>
            <b-button v-if="showElement([1])" class="mr-2" variant="success" @click="changeStatusAccession('aceptada')" :disabled="loading"><span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true" v-if="loading && accesion_company.status == 'aceptada'"></span>Aceptar</b-button>
            <b-button v-if="showElement([1])" class="mr-2" variant="danger" @click="changeStatusAccession('rechazada')" :disabled="loading"><span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true" v-if="loading && accesion_company.status == 'rechazada'"></span>Rechazar</b-button>
            <b-button @click="cancelForm()">Cancel</b-button>
          </div>
        </div>>
      </template>
    </b-modal>
  </div>
</template>