<script>
import api from "@/helpers/api-rest/api.js";
// import format from 'date-fns/format';
import { required, helpers } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import { errorMessages } from "@/helpers/validations/errorMessages";

// const decimalRegex = helpers.regex('decimal', /^-?\d+(\.\d+)?$/);
// Validador: Detecta si contiene comas o apóstrofes (separadores incorrectos)
const noCommasOrApostrophes = helpers.regex("noCommasOrApostrophes", /^[^,']*$/ );

// Validador: Solo caracteres numéricos y puntos
const noInvalidCharacters = helpers.regex("noInvalidCharacters", /^[0-9.]*$/);

// Validador: Número entero o decimal con punto
const validDecimal = helpers.regex("validDecimal", /^-?\d*(\.\d+)?$/);

// const noSpecialChars = helpers.regex("noSpecialChars", /^[a-zA-Z0-9áéíóúÁÉÍÓÚñÑ\s]*$/);
// const noSpaces = helpers.regex("noSpaces", /^\S*$/);
const onlyLetters = helpers.regex("onlyLetters", /^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]+$/);

const validDateFormat = helpers.withParams(
  { type: "validDateFormat" },
  value => {
    if (!value) return false; // Permitir vacío
    const regex = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/;
    return regex.test(value);
  }
);

const validEndDate = helpers.withParams(
  { type: "validEndDate" },
  (value, parentVm) => {
    // `parentVm` es el contexto del elemento actual (action)
    if (!value || !parentVm.init_date) return true; // Permitir vacío o si no hay `init_date`
    const startDate = new Date(parentVm.init_date);
    const endDate = new Date(value);
    return endDate >= startDate; // `end_date` debe ser mayor o igual que `init_date`
  }
);

const currentDate = new Date().toISOString().slice(0, 10);

// const isPdf = (value) => {
//   return value.endsWith(".pdf"); // Comprueba que el nombre termine en .pdf
// };

export default {
  props: {
    id: { type: String, default: "ModalActions" },
    action: {
      type: Object,
      default: () => {
        return {
            id: null,
            name: '',
            descriptions: '',
            init_date: currentDate,
            end_date: currentDate,
            responsables: '',
            risks: '',
            contingency_plan: '',
            education_activities: [],
            economic_forecats: [],
            essay_id: null
        };
      },
    },
  },
  data() {
    return {
        loading: false,
        errorMessages,
    };
  },
  computed: {
    modalTitle() {
        return this.action?.id ? this.action?.name : "Añadir acción";
    },
    submitButtonText() {
      return this.action?.id ? '' : 'Guardar';
    },
  },
    validations: {
        action: {
            name: {required},
            descriptions: {required},
            init_date: {required, validDateFormat},
            end_date: { required, validDateFormat, validEndDate },
            responsables: {required, onlyLetters},
            risks: {required},
            contingency_plan: {required},
            economic_forecats: {
                $each: {
                    material: {required},
                    cost: {
                        required,
                        noInvalidCharacters,
                        validDecimal,
                        noCommasOrApostrophes
                    }
                }
            },
            education_activities: {
                $each: {
                    name: {required},
                    methodology: {required}
                }    
            }
        }
    },
  methods: {
    loadData(){
        this.$v.$reset();
    },
    updateDateAction(event, field) {
        const value = event.target.value;
        field == 'init_date' ?  this.action.init_date = value : this.action.end_date = value;
    },
    createAction() {
        this.$v.$touch();
        if (this.$v.$invalid) {
            this.warningMsgValidation();
            return;
        }
        this.loading = true;
        console.log(this.action);
        const apiEndpoint = `/api/v1/essay-actions`;
        api.post({url:apiEndpoint, data:this.action})
        .then(response => {
            this.loading = false;
            const newAction = response?.data?.data;
            this.$emit('success', newAction);
            this.$bvModal.hide(this.id);
        })
        .catch(error => {
            this.loading = false;
            console.error("Error al crear la acción:", error);
        });
    },
    addRowActivity(action){
        action.education_activities.push({
            id: null,
            name: '',
            methodology: '',
        });
    },
    deleteRowActionActivity(key){
        this.action?.education_activities.splice(key, 1);
    },
    addRowForecast(action){
        action.economic_forecats.push({
            id: null,
            material: '',
            cost: '',
        });
    },
    deleteRowActionForecasts(key){
        this.action?.economic_forecats.splice(key, 1);
    },
    toggle(element) {
      element.showDetail = !element.showDetail;
    },
    cancelForm() {
        this.$bvModal.hide(this.id);
    },
    warningMsgValidation() {
      Swal.fire({
        icon: 'error',
        html: "Hay campos requeridos sin completar",
        timer: 2000,
        showConfirmButton: false,
      });
    },
  },
};
</script>
<template>
  
  <b-modal :id="id" :title="modalTitle" @shown="loadData" scrollable size="xl">
    <template #default="{ }">
        <form @submit.prevent="submitForm" enctype="multipart/form-data">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-4">
                            <h6>Nombre <span style="color: red;">*</span></h6>
                            <p v-if="action?.id" class="text-muted">
                                {{ action?.name }}
                            </p>
                            <div v-else class="form-group">
                                <input type="text" v-model="action.name" class="form-control" id="name" placeholder="Nombre" maxlength="200" :class="{ 'is-invalid': $v.action.name.$error }" />
                                <small class="text-muted">{{ action?.name?.length }}/200</small>
                                <div v-if="$v.action.name.$error" class="invalid-feedback">
                                    <div v-if="!$v.action.name.required">{{ errorMessages.required }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <h6>Ejecución inicial <span style="color: red;">*</span></h6>
                            <p v-if="action?.id" class="text-muted">
                                {{ action?.init_date }}
                            </p>
                            <div v-else class="form-group">
                                <!-- <input type="date" @input="updateDateAction($event, 'init_date')" id="initDate" class="form-control" placeholder="Ejecución inicial" /> -->
                                <input type="date" v-model="action.init_date" id="initDate" class="form-control" placeholder="Ejecución inicial"  :class="{ 'is-invalid': $v.action.init_date.$error }"/>
                                <div v-if="$v.action.init_date.$error" class="invalid-feedback">
                                    <div v-if="!$v.action.init_date.required">Este campo es obligatorio o incorrecto</div>
                                    <div v-else-if="!$v.action.init_date.validDateFormat">Introduce una fecha valida</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <h6>Ejecución final <span style="color: red;">*</span></h6>
                            <p v-if="action?.id" class="text-muted">
                                {{ action?.end_date }}
                            </p>
                            <div v-else class="form-group">
                                <!-- <input type="date" @input="updateDateAction($event, 'end_date')" id="endDate" class="form-control" placeholder="Ejecución final" /> -->
                                <input type="date" v-model="action.end_date" id="endDate" class="form-control" placeholder="Ejecución final"  :class="{ 'is-invalid': $v.action.end_date.$error }"/>
                                <div v-if="$v.action.end_date.$error" class="invalid-feedback">
                                    <div v-if="!$v.action.end_date.required">Este campo es obligatorio o incorrecto</div>
                                    <div v-else-if="!$v.action.end_date.validDateFormat">Introduce una fecha valida</div>
                                    <div v-else-if="!$v.action.end_date.validEndDate">
                                      La ejecución final no puede ser menor que la inicial
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12 mt-3">
                        <h6>Descripción <span style="color: red;">*</span></h6>
                        <p v-if="action?.id" class="text-muted">
                            {{ action?.descriptions }}
                        </p>
                        <div v-else class="form-group">
                            <textarea v-model="action.descriptions" class="form-control" id="descriptionAction" rows="3" placeholder="Descripción" maxlength="5000" :class="{ 'is-invalid': $v.action.descriptions.$error }"></textarea>
                            <small class="text-muted">{{ action?.descriptions?.length }}/5000</small>
                            <div v-if="$v.action.descriptions.$error" class="invalid-feedback">
                                <div v-if="!$v.action.descriptions.required">{{ errorMessages.required }}</div>
                            </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 mt-3">
                            <h6>Responsable <span style="color: red;">*</span></h6>
                            <p v-if="action?.id" class="text-muted">
                                {{ action?.responsables }}
                            </p>
                            <div v-else class="form-group">
                                <input type="text" v-model="action.responsables" id="actionResponsable" class="form-control" placeholder="Responsable" maxlength="200" :class="{ 'is-invalid': $v.action.responsables.$error }"/>
                                <small class="text-muted">{{ action?.responsables?.length }}/200</small>
                                <div v-if="$v.action.responsables.$error" class="invalid-feedback">
                                    <div v-if="!$v.action.responsables.required">{{ errorMessages.required }}</div>
                                    <div v-else-if="!$v.action.responsables.onlyLetters">{{ errorMessages.onlyLetters }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12 mt-3">
                        <h6>Riesgos <span style="color: red;">*</span></h6>
                        <p v-if="action?.id" class="text-muted">
                            {{ action?.risks }}
                        </p>
                        <div v-else class="form-group">
                            <textarea type="text" v-model="action.risks" id="actionRisks" class="form-control" rows="3" placeholder="Riesgos" maxlength="5000" :class="{ 'is-invalid': $v.action.risks.$error }"></textarea>
                            <small class="text-muted">{{ action?.risks?.length }}/5000</small>
                            <div v-if="$v.action.risks.$error" class="invalid-feedback">
                                <div v-if="!$v.action.risks.required">{{ errorMessages.required }}</div>
                            </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12 mt-3">
                        <h6>Plan de contingencia <span style="color: red;">*</span></h6>
                        <p v-if="action?.id" class="text-muted">
                            {{ action?.contingency_plan }}
                        </p>
                        <div v-else class="form-group">
                            <textarea type="text" v-model="action.contingency_plan" id="actionPlan" class="form-control" rows="3" placeholder="Plan de contingencia" maxlength="5000" :class="{ 'is-invalid': $v.action.contingency_plan.$error }"></textarea>
                            <small class="text-muted">{{ action?.contingency_plan?.length }}/5000</small>
                            <div v-if="$v.action.contingency_plan.$error" class="invalid-feedback">
                                <div v-if="!$v.action.contingency_plan.required">{{ errorMessages.required }}</div>
                            </div>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">Actividades educativas</h4>
                    <table class="table table-centered datatable dt-responsive nowrap mt-4" style="border-collapse: collapse; border-spacing: 0; width: 100%;">
                        <thead>
                            <tr>
                                <th scope="col">Nombre <i v-b-tooltip.hover title="Título de la actividad a realizar" class="far fa-question-circle font-size-12 ml-2"></i></th>
                                <th scope="col">Metodología <i v-b-tooltip.hover title="Breve descripción de la actividad y que metodología didáctica se usará para llevarla a cabo" class="far fa-question-circle font-size-12 ml-2"></i></th>
                            </tr>
                        </thead>
                        <tbody class="table-group-divider">
                            <tr v-for="(activity, i) in action.education_activities" :key="i" class="" :set="vactivity = $v.action.education_activities.$each[i]">
                                <td>
                                    <p v-if="action?.id" class="text-muted">
                                        {{ activity?.name }}
                                    </p>
                                    <div v-else class="form-group">
                                        <input type="text" v-model="activity.name" class="form-control" placeholder="Nombre" maxlength="200" :class="{ 'is-invalid': vactivity.name.$error }" />
                                        <small class="text-muted">{{ activity?.name?.length }}/200</small>
                                        <div v-if="vactivity.name.$error" class="invalid-feedback">
                                            <div v-if="!vactivity.name.required">{{ errorMessages.required }}</div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <p v-if="action?.id" class="text-muted">
                                        {{ activity?.methodology }}
                                    </p>
                                    <div v-else class="form-group">
                                        <input type="text" v-model="activity.methodology" class="form-control" placeholder="Metodología" maxlength="200"  :class="{ 'is-invalid': vactivity.methodology.$error }"/>                                     
                                        <small class="text-muted">{{ activity?.methodology?.length }}/200</small>
                                        <div v-if="vactivity.methodology.$error" class="invalid-feedback">
                                            <div v-if="!vactivity.methodology.required">{{ errorMessages.required }}</div>
                                        </div>
                                    </div>
                                </td>
                                <td v-if="!action?.id" class="text-center">
                                    <a
                                        href="javascript:void(0);"
                                        class="text-danger"
                                        v-b-tooltip.hover
                                        title="Eliminar"
                                        @click="deleteRowActionActivity(i)"
                                    >
                                        <i class="mdi mdi-trash-can font-size-18"></i>
                                    </a>
                                </td>
                            </tr>
                            <tr v-if="!action.education_activities || action.education_activities.length == 0">
                                <td colspan="4" class="text-center">
                                    No hay registros
                                </td>
                            </tr>
                        </tbody>
                        <tfoot v-if="!action?.id">
                            <tr>
                                <td colspan="8" class="text-right">
                                <a href="javascript:void(0);" class="text-success" @click="addRowActivity(action)">
                                    <i class="mdi mdi-plus-circle font-size-18"></i> Agregar actividad
                                </a>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">Previsión de coste económicos</h4>
                    <table class="table table-centered datatable dt-responsive nowrap mt-4" style="border-collapse: collapse; border-spacing: 0; width: 100%;">
                        <thead>
                            <tr>
                                <th scope="col">Material</th>
                                <th scope="col">Coste (€)</th>
                            </tr>
                        </thead>
                        <tbody class="table-group-divider">
                            <tr v-for="(forecast, j) in action.economic_forecats" :key="j" class="" :set="vforecast = $v.action.economic_forecats.$each[j]">
                                <td>
                                    <p v-if="action?.id" class="text-muted">
                                        {{ forecast?.material }}
                                    </p>
                                    <div v-else class="form-group">
                                        <input type="text" v-model="forecast.material" class="form-control" placeholder="Material" maxlength="200" :class="{ 'is-invalid': vforecast.material.$error }"/>
                                        <small class="text-muted">{{ forecast?.material?.length }}/200</small>
                                        <div v-if="vforecast.material.$error" class="invalid-feedback">
                                            <div v-if="!vforecast.material.required">{{ errorMessages.required }}</div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <p v-if="action?.id" class="text-muted">
                                        {{ forecast?.cost }}
                                    </p>
                                    <div v-else class="form-group">
                                        <input type="text" v-model="forecast.cost" class="form-control" placeholder="Coste (€)" maxlength="20" :class="{ 'is-invalid': vforecast.cost.$invalid && vforecast.cost.$dirty, }"/>
                                        <div v-if="vforecast.cost.$dirty" class="invalid-feedback">
                                            <span v-if="!vforecast.cost.required">
                                                Este campo es requerido
                                            </span>
                                            <span v-if="!vforecast.cost.noCommasOrApostrophes">
                                                El separador de decimales debe ser el punto (.).
                                            </span>
                                            <span v-else-if="!vforecast.cost.noInvalidCharacters">
                                                Solo se permiten valores numéricos.
                                            </span> 
                                            <span v-else-if="!vforecast.cost.validDecimal">
                                                Introduce un número válido.
                                            </span>
                                        </div>
                                    </div>
                                </td>
                                <td  v-if="!action?.id" class="text-center">
                                    <a
                                        href="javascript:void(0);"
                                        class="text-danger"
                                        v-b-tooltip.hover
                                        title="Eliminar"
                                        @click="deleteRowActionForecasts(j)"
                                    >
                                        <i class="mdi mdi-trash-can font-size-18"></i>
                                    </a>
                                </td>
                            </tr>
                            <tr v-if="!action.economic_forecats || action.economic_forecats.length == 0">
                                <td colspan="4" class="text-center">
                                    No hay registros
                                </td>
                            </tr>
                        </tbody>
                        <tfoot v-if="!action?.id">
                            <tr>
                                <td colspan="8" class="text-right">
                                <a href="javascript:void(0);" class="text-success" @click="addRowForecast(action)">
                                    <i class="mdi mdi-plus-circle font-size-18"></i> Agregar previsión
                                </a>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </form>
    </template>
    <template #modal-footer="{  }">
        <b-button  v-if="!action?.id" variant="success" @click="createAction()" :disabled="loading"><span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true" v-if="loading"></span>{{ submitButtonText }}</b-button>
        <!-- <b-button  v-if="showElement([1])" variant="success" @click="changeStatusContinuity('aceptada')" :disabled="loading"><span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true" v-if="loading && continuity.status == 'aceptada'"></span>Aceptar</b-button> -->
        <!-- <b-button  v-if="showElement([1])" variant="danger" @click="changeStatusContinuity('rechazada')" :disabled="loading"><span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true" v-if="loading && continuity.status == 'rechazada'"></span>Rechazar</b-button> -->
        <b-button @click="cancelForm()">Cancelar</b-button>
    </template>
  </b-modal>
</template>