<script>
import api from "@/helpers/api-rest/api.js";
import store from '@/state/store';
import qs from 'qs';
import { required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import { errorMessages } from "@/helpers/validations/errorMessages";

const isPdf = (value) => {
  return typeof value === "string" && value.toLowerCase().endsWith(".pdf");
};

export default {
  props: {
    id: { type: String, default: "ModalResignation" },
    essayId: { type: [Number, String] },
    centerId: { type: Number },
    resignation: {
      type: Object,
      default: () => {
        return {
          id: null,
          document_name: null
        };
      },
    },
  },
  data() {
    return {
      isModalOpen: false,
      dataUser: [],
      selectOptionsTrackingStatus: [],
      selectOptionsActions: [],
      collapseOpen: [],
      loading: false,
      errorMessages,
      validateFile: false,
    };
  },
  computed: {
    modalTitle() {
        return this.resignation?.id ? "Editar renuncia" : "Añadir renuncia";
    },
    submitButtonText() {
      return this.resignation?.id ? 'Guardar' : 'Añadir';
    },
  },
  validations() {
    return {
      resignation: {
        reason: { required },
        document_name: this.validateFile ? { required, isPdf } : {}
      }
    };
  },
  methods: {
    formatDate(d = new Date()) {
      return `${String(d.getDate()).padStart(2,'0')}${String(d.getMonth()+1).padStart(2,'0')}${d.getFullYear()}${String(d.getHours()).padStart(2,'0')}${String(d.getMinutes()).padStart(2,'0')}`;
    },
    showElement(roleId = [], show = true, showEdit = true) {
      const role = store.getters['auth/role'];
      if (roleId.includes(role.id) && show && showEdit) {
        return true;
      }
      return false;
    },
    onFileChange(event){
      this.file = event.target.files[0];
      this.resignation.document_name = this.formatDate() + '-' + this.file?.name;
      this.uploadFile(this.file, 'resignations')
      .then((response) => {
        const file = response?.data?.data;
        this.resignation.document_path = file.path;
      })
      .catch((error) => {
        console.error("Error al cargar el archivo:", error);
      });
    },
    uploadFile(file, folder){
      const formData = new FormData();
      formData.set("file", file);
      formData.append("path", folder);
      const config = { headers: { "content-type": "multipart/form-data" } };

      const apiEndpoint = `/api/v1/upload-file`;
      return api.post({ url: apiEndpoint, data: formData, config })
    },
    downloadFileAnexoXI(){
      const params = {
        'resignation': this.resignation,
        'essay_id': this.essayId,
        'center_id': this.centerId
      };

      console.log(params);

      const queryString = qs.stringify(params);
      const baseUrl = process.env.VUE_APP_API_HOST;
      const url = `${baseUrl}/anexo11?${queryString}`;      
      window.open(url, '_blank');
    },

    createResignation() {
      this.$v.$touch();
      if (this.$v.$invalid) {
          this.warningMsgValidation();
          this.loading = false;
          return;
      }
      this.loading = true;
      const apiEndpoint = `/api/v1/resignations`;
      this.resignation.essay_id = this.essayId;
      api.post({url:apiEndpoint, data:this.resignation})
      .then(response => {
          this.loading = false;
          const newResignation = response?.data?.data;
          this.$emit('success', newResignation);
          this.$bvModal.hide(this.id);
      })
      .catch(error => {
          this.loading = false;
          console.error("Error al crear la renuncia:", error);
      });
    },

    updateResignation() {
      this.$v.$touch();
      if (this.$v.$invalid) {
          this.warningMsgValidation();
          this.loading = false;
          return;
      }
      this.loading = true;
      const apiEndpoint = `/api/v1/resignations`;
      api.put({url:apiEndpoint, data:this.resignation, id:this.resignation.id})
      .then(response => {
          this.loading = false;
          const updatedResignation = response?.data?.data;
          this.$emit('success', updatedResignation);
          this.$bvModal.hide(this.id);
      })
      .catch(error => {
          this.loading = false;
          console.error("Error al editar la renuncia:", error);
      });
    },
    submitForm() {
      if(this.resignation?.id){
          return this.updateResignation();
      }
      return this.createResignation();
    },
    cancelForm() {
        this.$bvModal.hide(this.id);
    },
    saveResignation(status_user){
      this.validateFile= false,
      this.$v.$reset();
      this.resignation.status_user = status_user;
      this.submitForm();
    },
    addResignation(status_user) {
      this.validateFile = true;
      this.$v.$reset();
      this.resignation.status_user = status_user;
      this.submitForm();
    },
    loadData(){
      this.$v.$reset();
    },
    warningMsgValidation() {
      Swal.fire({
        icon: 'error',
        html: "Hay campos requeridos sin completar",
        timer: 2000,
        showConfirmButton: false,
      });
    },
  },
};
</script>
<template>
  <div>
    <b-modal :id="id" :title="modalTitle" @shown="loadData" scrollable size="xl">
      <template #default="{ }">
        <form @submit.prevent="submitForm" enctype="multipart/form-data">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-lg-12">
                  <h6>Justificación de renuncia <span style="color: red;">*</span></h6>
                  <div class="form-group">
                    <textarea v-model="resignation.reason" class="form-control" rows="3" placeholder="Introduce justificación" maxlength="5000" :class="{ 'is-invalid': $v.resignation.reason.$error }"></textarea>
                    <small class="text-muted">{{ resignation?.reason?.length }}/5000</small>
                    <div v-if="$v.resignation.reason.$error" class="invalid-feedback">
                      <div v-if="!$v.resignation.reason.required">{{ errorMessages.required }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-lg-12">
                  <b-alert show variant="warning">
                    <strong>Observaciones</strong><br>
                    <ul>
                      <li>Antes de adjuntar el <strong>Anexo XI</strong>, primero debes generarlo <a href="javascript:void(0);" @click.prevent="downloadFileAnexoXI()">aquí</a></li>
                      <li>Si has completado los dos campos anteriores, también aparecerán en el <strong>Anexo XI</strong></li>
                    </ul>
                  </b-alert>
                </div>
                <div class="col-lg-6 mt-3">
                  <h6>Adjuntar Anexo XI <span style="color: red;">*</span></h6>
                  <div class="form-group">
                    <div class="custom-file">
                      <input id="inputFile" type="file" name="file" class="custom-file-input" @change="onFileChange" accept="application/pdf" :class="{ 'is-invalid': $v.resignation.document_name.$error }"/>
                      <label class="custom-file-label" for="inputFile">{{ resignation.document_name || 'Seleccione un archivo' }}</label>
                      <div v-if="$v.resignation.document_name.$error" class="invalid-feedback">
                        <div v-if="!$v.resignation.document_name.required">{{ errorMessages.required }}</div>
                        <div v-else-if="!$v.resignation.document_name.isPdf">Solo se permiten archivos PDF.</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </template>
      <template #modal-footer="{  }">
        <div class="d-flex justify-content-between w-100">
          <b-button variant="primary" @click="saveResignation('editando')" :disabled="loading"><span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true" v-if="loading"></span>Guardar renuncia</b-button>
          <div>
            <b-button class="mr-2" variant="success" @click="addResignation('finalizado')" :disabled="loading"><span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true" v-if="loading"></span>Finalizar renuncia</b-button>
            <b-button @click="cancelForm()">Cancel</b-button>
          </div>
        </div>
        <!-- <b-button variant="success" @click="submitForm()" :disabled="loading"><span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true" v-if="loading"></span>{{ submitButtonText }}</b-button> -->
      </template>
    </b-modal>
  </div>
</template>