<script>
import api from "@/helpers/api-rest/api.js";
import store from '@/state/store';
import format from 'date-fns/format';
import qs from 'qs';
import Swal from "sweetalert2";
// import { generateValidations, onlyNumeric, validDateFormat, validEndDate, noInvalidCharacters,validDecimal,noCommasOrApostrophes } from "@/helpers/validations/validationsHelpers";
import { required, numeric, helpers } from "vuelidate/lib/validators";
import { errorMessages } from "@/helpers/validations/errorMessages";

const validDateFormat = helpers.withParams(
    { type: "validDateFormat" },
    value => {
      if (!value) return false; // Permitir vacío
      const regex = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/;
      return regex.test(value);
    }
  );
  
const validEndDate = helpers.withParams(
    { type: "validEndDate" },
    (value, parentVm) => {
        // `parentVm` es el contexto del elemento actual (action)
        if (!value || !parentVm.init_date) return true; // Permitir vacío o si no hay `init_date`
        const startDate = new Date(parentVm.init_date);
        const endDate = new Date(value);
        return endDate >= startDate; // `end_date` debe ser mayor o igual que `init_date`
    }
);

// Validador: Detecta si contiene comas o apóstrofes (separadores incorrectos)
const noCommasOrApostrophes = helpers.regex("noCommasOrApostrophes", /^[^,']*$/ );

// Validador: Solo caracteres numéricos y puntos
const noInvalidCharacters = helpers.regex("noInvalidCharacters", /^[0-9.]*$/);

// Validador: Número entero o decimal con punto
const validDecimal = helpers.regex("validDecimal", /^-?\d*(\.\d+)?$/);

const currentDate = new Date().toISOString().slice(0, 10);


export default {
    props: {
        id: { type: String, default: "ModalAccessionGeneral" },
        essayId: { type: [Number, String] },
        accession_general: {
            type: Object,
            default: () => {
                return {
                    id: null,
                    document_name: null,
                    typeAccession: null,
                    courses: '',
                    students_number: null,
                    accession_general_generate: {
                        students_number: null,
                        reason: '',
                        accession_input: '',
                        accession_general_activities: [{
                            id: '',
                            accession_general_id: '',
                            contingency_plan: '',
                            cost: '',
                            responsables: '',
                            risks: '',
                            description: '',
                            init_date: currentDate,
                            end_date: currentDate
                        }]
                    },
                };
            },
        },
    },
    data() {
        return {
            isModalOpen: false,
            dataUser: [],
            selectOptionsTrackingStatus: [],
            selectOptionsActions: [],
            collapseOpen: [],
            loading: false,
            essay: {},
            errorMessages,
            validateNumber: false
        };
    },
    computed: {
        modalTitle() {
            return this.accession_general?.id ? "Editar Anexo V" : "Generar Anexo V";
        },
        submitButtonText() {
        return this.accession_general?.id ? 'Generar' : 'Generar';
        },
    },
    validations() {
        return {
            accession_general: {
                accession_general_generate: {
                    students_number: this.validateNumber ? { required, numeric } : {},
                    // students_number: { required, numeric },
                    reason: { required },
                    accession_input: { required },
                    accession_general_activities: {
                        $each: {
                            responsables: { required },
                            contingency_plan: { required },
                            description: { required },
                            risks: { required },
                            init_date: { required, validDateFormat },
                            end_date: { required, validDateFormat, validEndDate },
                            cost: { required, noInvalidCharacters, validDecimal, noCommasOrApostrophes }
                        }
                    }
                }
            }
        };
    },
  methods: {
    loadDataEssay(){
        const apiEndpoint = `/api/v1/essays/${this.essayId}`;
        api.get({url:apiEndpoint})
        .then(response => {
            this.essay = response?.data?.data;
        })
        .catch(error => {
            console.error("Error loading data:", error);
        });
    },
    showElement(roleId = [], show = true, showRechazado = true, showRenuncia = true) {
      const role = store.getters['auth/role'];
      if (roleId.includes(role.id) && show && showRechazado && showRenuncia) {
        return true;
      }
      return false;
    },
    onFileChange(event){
      this.file = event.target.files[0];
      this.accesion_center.document_name = this.file?.name;
      this.uploadFile(this.file, 'accession')
      .then((response) => {
        const file = response?.data?.data;
        this.accesion_center.document_path = file.path;
      })
      .catch((error) => {
        console.error("Error al cargar el archivo:", error);
      });
    },
    uploadFile(file, folder){
      const formData = new FormData();
      formData.set("file", file);
      formData.append("path", folder);
      const config = { headers: { "content-type": "multipart/form-data" } };

      const apiEndpoint = `/api/v1/upload-file`;
      return api.post({ url: apiEndpoint, data: formData, config })
    },
    toggleCollapse(index) {
        this.$set(this.collapseOpen, index, !this.collapseOpen[index]);
    },
    downloadFiles(fileName, folderID){
        const params = {
            'fileName': fileName,
            'folderID': folderID,
        };

        const queryString = qs.stringify(params);
        const baseUrl = process.env.VUE_APP_API_HOST;
        const url = `${baseUrl}/download-drive?${queryString}`;
        window.open(url, '_blank');
    },
    createAccessionGeneral() {
        this.$v.$touch();
        if (this.$v.$invalid) {
            this.warningMsgValidation();
            this.loading = false;
            return;
        }
        this.loading = true;
        const apiEndpoint = `/api/v1/accession-general-generates`;
        this.accession_general.essay_id = this.essayId;
        api.post({url:apiEndpoint, data:this.accession_general})
        .then(response => {
            this.loading = false;
            const newAccessionGeneral = response?.data?.data;
            this.$emit('success', newAccessionGeneral);
            this.$bvModal.hide(this.id);
            this.successmsg(newAccessionGeneral.url);
        })
        .catch(error => {
            this.loading = false;
            console.error("Error al crear adhesion de empresa:", error);
        });
    },
    updateAccessionGeneral() {
        
        this.$v.$touch();
        if (this.$v.$invalid) {
            console.log(this.$v);
            this.warningMsgValidation();
            this.loading = false;
            return;
        }
        this.loading = true;
        const apiEndpoint = `/api/v1/accession-general-generates`;
        api.put({url:apiEndpoint, data:this.accession_general, id:this.accession_general.accession_general_generate.id})
        .then(response => {
            this.loading = false;
            const updatedAccessionGeneral = response?.data?.data;
            this.$emit('success', updatedAccessionGeneral);
            this.$bvModal.hide(this.id);
            this.successmsg(updatedAccessionGeneral.url);
        })
        .catch(error => {
            this.loading = false;
            console.error("Error al editar adhesion de empresa:", error);
        });
    },
    changeStatusAccession(status){
      this.accesion_center.status = status;
      this.submitForm();
    },
    submitForm() {
      if(this.accession_general?.id){
          return this.updateAccessionGeneral();
      }
      return this.createAccessionGeneral();
    },
    cancelForm() {
        this.$bvModal.hide(this.id);
    },
    addRowActivity(){
        const currentDate = new Date().toISOString().slice(0, 10);
        this.accession_general.accession_general_generate.accession_general_activities.push({
            id: '',
            accession_general_id: this.accession_general.accession_general_generate.id,
            contingency_plan: '',
            cost: '',
            responsables: '',
            risks: '',
            description: '',
            init_date: currentDate,
            end_date: currentDate
        });
    },
    deleteRowActivity(key){
        if (this.accession_general.accession_general_generate.accession_general_activities.length <= 1) {
        Swal.fire({
          title: "No se puede borrar",
          text: "Debe haber al menos una actividad.",
          icon: "warning",
          confirmButtonColor: "#34c38f",
          confirmButtonText: "Aceptar"
        });
        return;
      }
        Swal.fire({
            title: "¿Estás seguro?",
            text: "Una vez eliminado no podrá recuperarlo",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#34c38f",
            cancelButtonColor: "#f46a6a",
            confirmButtonText: "Sí, borrar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                this.accession_general?.accession_general_generate?.accession_general_activities.splice(key, 1);
            }
        });
    },
    formatDate(date) {
      return format(new Date(date), 'dd/MM/yyyy');
    },
    successmsg(url) {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
            confirmButton: "btn btn-success",
            },
            buttonsStyling: false
        });

        console.log(url);

        swalWithBootstrapButtons.fire({
            title: "!Buen trabajo!",
            html: `
            <div style="position: relative;">
                <p>Se ha generado el Anexo V correctamente<br><br></p>
                <iframe src="${url}" frameborder="0" style="height: 70vh; width: 100%;"></iframe>
            </div>
            `,
            icon: "success",
            width: 1000,
            padding: 20,
            allowOutsideClick: false,
            showCancelButton: false,
            confirmButtonText: "Aceptar",
        }).then((result) => {
            if (result.isConfirmed) {
                this.$swal.close();
            }
        });
    },
    warningMsgValidation() {
      Swal.fire({
        icon: 'error',
        html: "Hay campos requeridos sin completar",
        timer: 2000,
        showConfirmButton: false,
      });
    },
    loadData(){
        this.validateNumber = this.accession_general.typeAccession == 'center' ? true : false;        
        this.loadDataEssay();
    },
  },
};
</script>
<template>
  <div>
    <b-modal :id="id" :title="modalTitle" @shown="loadData" scrollable size="xl">
      <template #default="{ }">
        <form @submit.prevent="submitForm" enctype="multipart/form-data">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-12">
                            <h6>Título</h6>
                            <p class="text-muted">
                                {{ accession_general?.essay?.name }}
                            </p>
                        </div>
                        <div v-if="accession_general.typeAccession == 'center'" class="col-lg-12">
                            <h6>Número de alumnado <span class="text-danger">*</span></h6>
                            <div v-if="essay?.essay_status_id == 7 || essay?.essay_status_id == 4 || essay?.essay_status_id == 6">
                                <p class="text-muted">
                                    {{ accession_general?.accession_general_generate?.students_number }}
                                </p>
                            </div>
                            <div v-else class="form-group">
                                <!-- <input v-model="accession_general.accession_general_generate.students_number" type="text" class="form-control" id="name" placeholder="Número de alumnado"> -->
                                <input v-model="accession_general.accession_general_generate.students_number" type="text" class="form-control" id="name" placeholder="Número de alumnado" :class="{ 'is-invalid': $v.accession_general.accession_general_generate.students_number.$error }">
                                <div v-if="$v.accession_general.accession_general_generate.students_number.$error" class="invalid-feedback">
                                    <div v-if="!$v.accession_general.accession_general_generate.students_number.required">{{ errorMessages.required }}</div>
                                    <div v-else-if="!$v.accession_general.accession_general_generate.students_number.numeric">El campo debe ser numérico.</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <h6>Justificación de la adhesión <span class="text-danger">*</span></h6>
                            <div v-if="essay?.essay_status_id == 7 || essay?.essay_status_id == 4 || essay?.essay_status_id == 6">
                                <p class="text-muted">
                                    {{ accession_general?.accession_general_generate?.reason }}
                                </p>
                            </div>
                            <div v-else class="form-group">
                                <!-- <textarea v-model="accession_general.accession_general_generate.reason" class="form-control" rows="3" maxlength="5000" placeholder="Introduce justificación de la adhesión"></textarea> -->
                                <textarea v-model="accession_general.accession_general_generate.reason" class="form-control" rows="3" maxlength="5000" placeholder="Introduce justificación de la adhesión" :class="{ 'is-invalid': $v.accession_general.accession_general_generate.reason.$error }"></textarea>
                                <small class="text-muted">{{ accession_general?.accession_general_generate?.reason?.length }}/5000</small>
                                <div v-if="$v.accession_general.accession_general_generate.reason.$error" class="invalid-feedback">
                                    <div v-if="!$v.accession_general.accession_general_generate.reason.required">{{ errorMessages.required }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <h6>Aportación al proyecto <span class="text-danger">*</span></h6>
                            <div v-if="essay?.essay_status_id == 7 || essay?.essay_status_id == 4 || essay?.essay_status_id == 6">
                                <p class="text-muted">
                                    {{ accession_general?.accession_general_generate?.accession_input }}
                                </p>
                            </div>
                            <div v-else class="form-group">
                                <!-- <textarea v-model="accession_general.accession_general_generate.accession_input" class="form-control" rows="3" maxlength="5000" placeholder="Introduce aportación al proyecto"></textarea> -->
                                <textarea v-model="accession_general.accession_general_generate.accession_input" class="form-control" rows="3" maxlength="5000" placeholder="Introduce aportación al proyecto" :class="{ 'is-invalid': $v.accession_general.accession_general_generate.accession_input.$error }"></textarea>
                                <small class="text-muted">{{ accession_general?.accession_general_generate?.accession_input?.length }}/5000</small>
                                <div v-if="$v.accession_general.accession_general_generate.accession_input.$error" class="invalid-feedback">
                                    <div v-if="!$v.accession_general.accession_general_generate.accession_input.required">{{ errorMessages.required }}</div>
                                </div>
                            </div>
                        </div>
                        <b-alert class="col-lg-12" show variant="warning">
                            <strong>Observaciones</strong><br>
                            <ul>
                                <li>Debe de haber mínimo una actividad.</li>
                            </ul>
                        </b-alert>
                        <div class="col-lg-12 mt-3">
                            <h6>Propuesta de actividades</h6>
                            <!-- <b-card no-body class="mb-1 shadow-none" v-for="(activity, i) in accession_general.accession_general_generate.accession_general_activities" :key="i"> -->
                            <b-card no-body class="mb-1 shadow-none" v-for="(activity, i) in accession_general.accession_general_generate.accession_general_activities" :key="i" :set="vactivity = $v.accession_general.accession_general_generate.accession_general_activities.$each[i]">
                                <b-card-header header-tag="header" class="p-1" role="tab">
                                <h6 class="m-0 d-flex justify-content-between align-items-center">
                                    <div class="form-inline">
                                        <span>Actividad <small class="font-italic ml-3">({{ formatDate(activity.init_date) }} - {{ formatDate(activity.end_date) }})</small></span>
                                    </div>
                                    <div>
                                        <b-icon style="margin-right: 10px" :icon="collapseOpen[i] ? 'chevron-down' : 'chevron-up'" @click="toggleCollapse('collapse-' + i)"  v-b-toggle="'collapse-' + i"></b-icon>
                                        <a v-if="essay?.essay_status_id != 7" href="javascript:void(0);" class="text-danger" v-b-tooltip.hover title="Eliminar"
                                            @click="deleteRowActivity(i)">
                                            <i class="mdi mdi-trash-can font-size-18"></i>
                                        </a>
                                    </div>
                                </h6>
                                </b-card-header>
                                <b-collapse :id="'collapse-' + i" accordion="variation-accordion" role="tabpanel">
                                    <b-card-body>
                                        <b-card-text>
                                            <div class="row">
                                                <div class="col-lg-4">
                                                    <div class="form-group">
                                                        <label for="initDate">Ejecución inicial <span style="color: red;">*</span></label>
                                                        <div v-if="essay?.essay_status_id == 7 || essay?.essay_status_id == 4 || essay?.essay_status_id == 6">
                                                            <p class="text-muted">
                                                                {{ activity?.init_date }}
                                                            </p>
                                                        </div>
                                                        <!-- <input v-else type="date" v-model="activity.init_date" id="initDate" class="form-control" placeholder="Ejecución inicial"/> -->
                                                        <input v-else type="date" v-model="activity.init_date" id="initDate" class="form-control" placeholder="Ejecución inicial" :class="{ 'is-invalid': vactivity.init_date.$error }"/>
                                                        <div v-if="vactivity.init_date.$error" class="invalid-feedback">
                                                            <div v-if="!vactivity.init_date.required">Este campo es obligatorio o incorrecto</div>
                                                            <div v-else-if="!vactivity.init_date.validDateFormat">Introduce una fecha valida</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-4">
                                                    <div class="form-group">
                                                        <label for="endDate">Ejecución final <span style="color: red;">*</span></label>
                                                        <div v-if="essay?.essay_status_id == 7 || essay?.essay_status_id == 4 || essay?.essay_status_id == 6">
                                                            <p class="text-muted">
                                                                {{ activity?.end_date }}
                                                            </p>
                                                        </div>
                                                        <!-- <input v-else type="date" v-model="activity.end_date" id="endDate" class="form-control" placeholder="Ejecución final"/> -->
                                                        <input v-else type="date" v-model="activity.end_date" id="endDate" class="form-control" placeholder="Ejecución final" :class="{ 'is-invalid': vactivity.end_date.$error }"/>
                                                        <div v-if="vactivity.end_date.$error" class="invalid-feedback">
                                                            <div v-if="!vactivity.end_date.required">Este campo es obligatorio o incorrecto</div>
                                                            <div v-else-if="!vactivity.end_date.validDateFormat">Introduce una fecha valida</div>
                                                            <div v-else-if="!vactivity.end_date.validEndDate">La ejecución final no puede ser menor que la inicial</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-4">
                                                    <div class="form-group">
                                                        <label for="cost">Coste <span style="color: red;">*</span></label>
                                                        <div v-if="essay?.essay_status_id == 7 || essay?.essay_status_id == 4 || essay?.essay_status_id == 6">
                                                            <p class="text-muted">
                                                                {{ activity?.cost }}
                                                            </p>
                                                        </div>
                                                        <input v-else v-model="activity.cost" type="text" class="form-control" id="name" placeholder="Coste (€)" :class="{ 'is-invalid': vactivity.cost.$invalid && vactivity.cost.$dirty, }">
                                                        <div v-if="vactivity.cost.$dirty" class="invalid-feedback">
                                                            <span v-if="!vactivity.cost.required">{{ errorMessages.required }}</span>
                                                            <span v-if="!vactivity.cost.noCommasOrApostrophes">El separador de decimales debe ser el punto (.).</span>
                                                            <span v-else-if="!vactivity.cost.noInvalidCharacters">Solo se permiten valores numéricos.</span> 
                                                            <span v-else-if="!vactivity.cost.validDecimal">Introduce un número válido.</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <div class="form-group">
                                                        <label for="risks">Descripción <span style="color: red;">*</span></label><i v-b-tooltip.hover title="Describe actividad con implicación de los participantes y responsables" class="far fa-question-circle font-size-12 ml-2"></i>
                                                        <div v-if="essay?.essay_status_id == 7 || essay?.essay_status_id == 4 || essay?.essay_status_id == 6">
                                                            <p class="text-muted">
                                                                {{ activity?.description }}
                                                            </p>
                                                        </div>
                                                        <textarea v-else v-model="activity.description" class="form-control" id="risks" rows="3" maxlength="5000" placeholder="Describe actividad con implicación de los participantes y responsables"  :class="{ 'is-invalid': vactivity.description.$error }"></textarea>
                                                        <small class="text-muted">{{ activity?.description?.length }}/200</small>
                                                        <div v-if="vactivity.description.$error" class="invalid-feedback">
                                                            <div v-if="!vactivity.description.required">{{ errorMessages.required }}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-12">
                                                    <div class="form-group">
                                                        <label for="responsable">Responsable <span style="color: red;">*</span></label>
                                                        <div v-if="essay?.essay_status_id == 7 || essay?.essay_status_id == 4 || essay?.essay_status_id == 6">
                                                            <p class="text-muted">
                                                                {{ activity?.responsables }}
                                                            </p>
                                                        </div>
                                                        <input v-else v-model="activity.responsables" type="text" class="form-control" id="name" maxlength="200" placeholder="Responsable" :class="{ 'is-invalid': vactivity.responsables.$error }">
                                                        <small class="text-muted">{{ activity?.responsables?.length }}/200</small>
                                                        <div v-if="vactivity.responsables.$error" class="invalid-feedback">
                                                            <div v-if="!vactivity.responsables.required">{{ errorMessages.required }}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-12">
                                                    <div class="form-group">
                                                        <label for="risks">Riesgos <span style="color: red;">*</span></label>
                                                        <div v-if="essay?.essay_status_id == 7 || essay?.essay_status_id == 4 || essay?.essay_status_id == 6">
                                                            <p class="text-muted">
                                                                {{ activity?.risks }}
                                                            </p>
                                                        </div>
                                                        <textarea v-else v-model="activity.risks" class="form-control" id="risks" rows="3" maxlength="5000" placeholder="Introduce riesgos" :class="{ 'is-invalid': vactivity.risks.$error }"></textarea>
                                                        <small class="text-muted">{{ activity?.risks?.length }}/5000</small>
                                                        <div v-if="vactivity.risks.$error" class="invalid-feedback">
                                                            <div v-if="!vactivity.risks.required">{{ errorMessages.required }}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-12">
                                                    <div class="form-group">
                                                        <label for="contingency_plan">Plan de contingencia <span style="color: red;">*</span></label>
                                                        <div v-if="essay?.essay_status_id == 7 || essay?.essay_status_id == 4 || essay?.essay_status_id == 6">
                                                            <p class="text-muted">
                                                                {{ activity?.contingency_plan }}
                                                            </p>
                                                        </div>
                                                        <textarea v-else v-model="activity.contingency_plan" class="form-control" id="contingency_plan" rows="3" maxlength="5000" placeholder="Introduce plan de contingencia" :class="{ 'is-invalid': vactivity.contingency_plan.$error }"></textarea>
                                                        <small class="text-muted">{{ activity?.contingency_plan?.length }}/5000</small>
                                                        <div v-if="vactivity.contingency_plan.$error" class="invalid-feedback">
                                                            <div v-if="!vactivity.contingency_plan.required">{{ errorMessages.required }}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </b-card-text>
                                    </b-card-body>
                                </b-collapse>
                            </b-card>
                            <a v-if="showElement([3], essay?.essay_status_id != 7, essay?.essay_status_id != 4, essay?.essay_status_id != 6)" href="javascript:void(0);" class="text-success" @click="addRowActivity()">
                                <i class="mdi mdi-plus-circle font-size-18"></i> Agregar nueva actividad
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </form>
      </template>
      <template #modal-footer="{  }">
        <b-button  v-if="showElement([3], essay?.essay_status_id != 7, essay?.essay_status_id != 4, essay?.essay_status_id != 6)" variant="success" @click="submitForm()" :disabled="loading"><span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true" v-if="loading"></span>{{ submitButtonText }}</b-button>
        <b-button @click="cancelForm()">Cancel</b-button>
      </template>
    </b-modal>
  </div>
</template>