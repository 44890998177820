<script>
import api from "@/helpers/api-rest/api.js";
import store from '@/state/store';
import qs from 'qs';
import { required, email } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import { errorMessages } from "@/helpers/validations/errorMessages";

const isPdf = (value) => {
  return typeof value === "string" && value.toLowerCase().endsWith(".pdf");
};

export default {
  props: {
    id: { type: String, default: "ModalAccessionCompany" },
    essayId: { type: [Number, String] },
    centerId: { type: Number },
    accesion_company: {
      type: Object,
      default: () => {
        return {
          id: null,
          document_name: null
        };
      },
    },
  },
  data() {
    return {
      isModalOpen: false,
      dataUser: [],
      selectOptionsTrackingStatus: [],
      selectOptionsActions: [],
      collapseOpen: [],
      loading: false,
      errorMessages,
      validateFile: false
    };
  },
  computed: {
    modalTitle() {
        return this.accesion_company?.id ? "Editar adhesión" : "Añadir adhesión";
    },
    submitButtonText() {
      return this.accesion_company?.id ? 'Guardar' : 'Añadir';
    },
  },
  validations() {
    return {
      accesion_company: {
        reason: {required},
        name: {required},
        cif: {required},
        address: {required},
        responsable: {required},
        email: {required, email},
        document_name: this.validateFile ? { required, isPdf } : {}
      }
    };
  },
  methods: {
    formatDate(d = new Date()) {
      return `${String(d.getDate()).padStart(2,'0')}${String(d.getMonth()+1).padStart(2,'0')}${d.getFullYear()}${String(d.getHours()).padStart(2,'0')}${String(d.getMinutes()).padStart(2,'0')}`;
    },
    showElement(roleId = [], show = true, showRechazado = true, showRenuncia = true) {
      const role = store.getters['auth/role'];
      if (roleId.includes(role.id) && show && showRechazado && showRenuncia) {
        return true;
      }
      return false;
    },
    onFileChange(event){
      this.file = event.target.files[0];
      this.accesion_company.document_name = this.formatDate() + '-' + this.file?.name;
      this.uploadFile(this.file, 'accession')
      .then((response) => {
        const file = response?.data?.data;
        this.accesion_company.document_path = file.path;
      })
      .catch((error) => {
        console.error("Error al cargar el archivo:", error);
      });
    },
    uploadFile(file, folder){
      const formData = new FormData();
      formData.set("file", file);
      formData.append("path", folder);
      const config = { headers: { "content-type": "multipart/form-data" } };

      const apiEndpoint = `/api/v1/upload-file`;
      return api.post({ url: apiEndpoint, data: formData, config })
    },
    downloadFileAnexoVI(){
      const params = {
        'accesion_company': this.accesion_company,
        'essay_id': this.essayId,
        'center_id': this.centerId
      };
      const queryString = qs.stringify(params);
      const baseUrl = process.env.VUE_APP_API_HOST;
      const url = `${baseUrl}/anexo6?${queryString}`;      
      window.open(url, '_blank');
    },
    downloadFiles(fileName, folderID){
        const params = {
            'fileName': fileName,
            'folderID': folderID,
        };

        const queryString = qs.stringify(params);
        const baseUrl = process.env.VUE_APP_API_HOST;
        const url = `${baseUrl}/download-drive?${queryString}`;
        window.open(url, '_blank');
    },
    createAccessionCompany() {
      this.$v.$touch();
      if (this.$v.$invalid) {
          this.warningMsgValidation();
          return;
      }
      this.loading = true;
      const apiEndpoint = `/api/v1/accession-company-partners`;
      this.accesion_company.essay_id = this.essayId;
      api.post({url:apiEndpoint, data:this.accesion_company})
      .then(response => {
          this.loading = false;
          const newAccession = response?.data?.data;
          this.$emit('success', newAccession);
          this.$bvModal.hide(this.id);
      })
      .catch(error => {
          this.loading = false;
          console.error("Error al crear adhesion de empresa:", error);
      });
    },
    updateAccessionCompany() {
      this.$v.$touch();
      if (this.$v.$invalid) {
          this.warningMsgValidation();
          return;
      }
      this.loading = true;
      const apiEndpoint = `/api/v1/accession-company-partners`;
      api.put({url:apiEndpoint, data:this.accesion_company, id:this.accesion_company.id})
      .then(response => {
          this.loading = false;
          const updatedAccession = response?.data?.data;
          this.$emit('success', updatedAccession);
          this.$bvModal.hide(this.id);
      })
      .catch(error => {
          this.loading = false;
          console.error("Error al editar adhesion de empresa:", error);
      });
    },
    changeStatusAccession(status){
      this.accesion_company.status = status;
      this.submitForm();
    },
    submitForm() {
      if(this.accesion_company?.id){
          return this.updateAccessionCompany();
      }
      return this.createAccessionCompany();
    },
    cancelForm() {
        this.$bvModal.hide(this.id);
    },
    loadData(){
      this.$v.$reset();
    },
    saveAccession(status_user) {
      this.validateFile = false; // No validar file
      this.$v.$reset();
      this.accesion_company.status_user = status_user;
      this.submitForm();
    },
    addAccession(status_user) {
      this.validateFile = true;
      this.$v.$reset();
      this.accesion_company.status_user = status_user;
      this.submitForm();
    },
    warningMsgValidation() {
      Swal.fire({
        icon: 'error',
        html: "Hay campos requeridos sin completar",
        timer: 2000,
        showConfirmButton: false,
      });
    },
  },
};
</script>
<template>
  <div>
    <b-modal :id="id" :title="modalTitle" @shown="loadData" scrollable size="xl">
      <template #default="{ }">
        <form @submit.prevent="submitForm" enctype="multipart/form-data">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-lg-12">
                  <h6>Justificación <span style="color: red;">*</span></h6>
                  <div v-if="accesion_company?.essay?.essay_status_id == 7 || accesion_company?.essay?.essay_status_id == 4 || accesion_company?.essay?.essay_status_id == 6">
                    <p class="text-muted">
                        {{ accesion_company?.reason }}
                    </p>
                  </div>
                  <!-- <p v-if="showElement([1])" class="text-muted">
                      {{ accesion_company?.reason }}
                  </p> -->
                  <div v-else class="form-group">
                    <textarea v-model="accesion_company.reason" class="form-control" rows="3" placeholder="Introduce justificación" maxlength="5000" :class="{ 'is-invalid': $v.accesion_company.reason.$error }"></textarea>
                    <small class="text-muted">{{ accesion_company?.reason?.length }}/5000</small>
                    <div v-if="$v.accesion_company.reason.$error" class="invalid-feedback">
                      <div v-if="!$v.accesion_company.reason.required">{{ errorMessages.required }}</div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <h6>Nombre <span style="color: red;">*</span></h6>
                  <div v-if="accesion_company?.essay?.essay_status_id == 7 || accesion_company?.essay?.essay_status_id == 4 || accesion_company?.essay?.essay_status_id == 6">
                    <p class="text-muted">
                        {{ accesion_company?.name }}
                    </p>
                  </div>
                  <!-- <p v-if="showElement([1])" class="text-muted">
                      {{ accesion_company?.name }}
                  </p> -->
                  <div v-else class="form-group">
                    <input v-model="accesion_company.name" type="text" class="form-control" id="name" placeholder="Nombre" maxlength="200" :class="{ 'is-invalid': $v.accesion_company.name.$error }">
                    <small class="text-muted">{{ accesion_company?.name?.length }}/200</small>
                    <div v-if="$v.accesion_company.name.$error" class="invalid-feedback">
                      <div v-if="!$v.accesion_company.name.required">{{ errorMessages.required }}</div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                    <h6>CIF <span style="color: red;">*</span></h6>
                    <div v-if="accesion_company?.essay?.essay_status_id == 7 || accesion_company?.essay?.essay_status_id == 4 || accesion_company?.essay?.essay_status_id == 6">
                      <p class="text-muted">
                          {{ accesion_company?.cif }}
                      </p>
                    </div>
                    <!-- <p v-if="showElement([1])" class="text-muted">
                        {{ accesion_company?.cif }}
                    </p> -->
                    <div v-else class="form-group">
                      <input v-model="accesion_company.cif" type="text" class="form-control" id="cif" placeholder="CIF" maxlength="10" :class="{ 'is-invalid': $v.accesion_company.cif.$error }">
                      <small class="text-muted">{{ accesion_company?.cif?.length }}/10</small>
                      <div v-if="$v.accesion_company.cif.$error" class="invalid-feedback">
                        <div v-if="!$v.accesion_company.cif.required">{{ errorMessages.required }}</div>
                      </div>
                    </div>
                </div>
                <div class="col-lg-12">
                    <h6>Dirección <span style="color: red;">*</span></h6>
                    <div v-if="accesion_company?.essay?.essay_status_id == 7 || accesion_company?.essay?.essay_status_id == 4 || accesion_company?.essay?.essay_status_id == 6">
                      <p class="text-muted">
                          {{ accesion_company?.address }}
                      </p>
                    </div>
                    <!-- <p v-if="showElement([1])" class="text-muted">
                        {{ accesion_company?.address }}
                    </p> -->
                    <div v-else class="form-group">
                      <textarea v-model="accesion_company.address" class="form-control" rows="3" placeholder="Dirección" maxlength="5000" :class="{ 'is-invalid': $v.accesion_company.address.$error }"></textarea>
                      <small class="text-muted">{{ accesion_company?.address?.length }}/5000</small>
                      <div v-if="$v.accesion_company.address.$error" class="invalid-feedback">
                        <div v-if="!$v.accesion_company.address.required">{{ errorMessages.required }}</div>
                      </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <h6>Responsable <span style="color: red;">*</span></h6>
                    <div v-if="accesion_company?.essay?.essay_status_id == 7 || accesion_company?.essay?.essay_status_id == 4 || accesion_company?.essay?.essay_status_id == 6">
                      <p class="text-muted">
                          {{ accesion_company?.responsable }}
                      </p>
                    </div>
                    <!-- <p v-if="showElement([1])" class="text-muted">
                        {{ accesion_company?.responsable }}
                    </p> -->
                    <div v-else class="form-group">
                      <input v-model="accesion_company.responsable" type="text" class="form-control" id="responsable" placeholder="Responsable" maxlength="200" :class="{ 'is-invalid': $v.accesion_company.responsable.$error }">
                      <small class="text-muted">{{ accesion_company?.responsable?.length }}/200</small>
                      <div v-if="$v.accesion_company.responsable.$error" class="invalid-feedback">
                        <div v-if="!$v.accesion_company.responsable.required">{{ errorMessages.required }}</div>
                      </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <h6>Email <span style="color: red;">*</span></h6>
                    <div v-if="accesion_company?.essay?.essay_status_id == 7 || accesion_company?.essay?.essay_status_id == 4 || accesion_company?.essay?.essay_status_id == 6">
                      <p class="text-muted">
                          {{ accesion_company?.email }}
                      </p>
                    </div>
                    <!-- <p v-if="showElement([1])" class="text-muted">
                      {{ accesion_company?.email }}
                    </p> -->
                    <div v-else class="form-group">
                      <input v-model="accesion_company.email" type="text" class="form-control" id="email" placeholder="Email" maxlength="200" :class="{ 'is-invalid': $v.accesion_company.email.$error }">
                      <small class="text-muted">{{ accesion_company?.email?.length }}/200</small>
                      <div v-if="$v.accesion_company.email.$error" class="invalid-feedback">
                        <div v-if="!$v.accesion_company.email.required">{{ errorMessages.required }}</div>
                        <div v-else-if="!$v.accesion_company.email.email">{{ errorMessages.email }}</div>
                      </div>
                    </div>
                </div>
              </div>
              <div v-if="accesion_company?.essay?.essay_status_id != 7 && accesion_company?.essay?.essay_status_id != 4 && accesion_company?.essay?.essay_status_id != 6" class="row mt-4">
                <div class="col-lg-12">
                  <b-alert show variant="warning">
                    <strong>Observaciones</strong><br>
                    <ul>
                      <li>Antes de adjuntar el <strong>Anexo VI</strong>, primero debes generarlo <a href="javascript:void(0);" @click.prevent="downloadFileAnexoVI()">aquí</a></li>
                      <li>Si has completado los dos campos anteriores, también aparecerán en el <strong>Anexo VI</strong></li>
                    </ul>
                  </b-alert>
                </div>
                <div v-if="showElement([3], accesion_company?.essay?.essay_status_id != 7)" class="col-lg-6 mt-3">
                  <h6>Adjuntar Anexo VI <span style="color: red;">*</span></h6>
                  <div class="form-group">
                    <div class="custom-file">
                      <input id="inputFile" type="file" name="file" class="custom-file-input" @change="onFileChange" accept="application/pdf" :class="{ 'is-invalid': $v.accesion_company.document_name.$error }"/>
                      <label class="custom-file-label" for="inputFile">{{ accesion_company.document_name || 'Seleccione un archivo' }}</label>
                      <div v-if="$v.accesion_company.document_name.$error" class="invalid-feedback">
                        <div v-if="!$v.accesion_company.document_name.required">{{ errorMessages.required }}</div>
                        <div v-else-if="!$v.accesion_company.document_name.isPdf">Solo se permiten archivos PDF.</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="row mt-3">
                <div class="col-lg-12 mt-3">
                  <h6>Anexo VI</h6>
                  <b-button variant="info" @click.prevent="downloadFiles(accesion_company?.document_name, accesion_company?.essay?.folder_google_id)"><i class="fas fa-file-download mr-2"></i> Descargar</b-button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </template>
      <template #modal-footer="{  }">
        <div class="d-flex justify-content-between w-100">
          <b-button v-if="showElement([3], accesion_company?.essay?.essay_status_id != 7, accesion_company?.essay?.essay_status_id != 4, accesion_company?.essay?.essay_status_id != 6)" variant="primary" @click="saveAccession('editando')" :disabled="loading"><span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true" v-if="loading"></span>Guardar adhesión</b-button>
          <div>
            <b-button v-if="showElement([3], accesion_company?.essay?.essay_status_id != 7, accesion_company?.essay?.essay_status_id != 4, accesion_company?.essay?.essay_status_id != 6)" class="mr-2" variant="success" @click="addAccession('finalizado')" :disabled="loading"><span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true" v-if="loading"></span>Finalizar adhesión</b-button>
            <b-button v-if="showElement([1])" class="mr-2" variant="success" @click="changeStatusAccession('aceptada')" :disabled="loading"><span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true" v-if="loading && accesion_company.status == 'aceptada'"></span>Aceptar</b-button>
            <b-button v-if="showElement([1])" class="mr-2" variant="danger" @click="changeStatusAccession('rechazada')" :disabled="loading"><span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true" v-if="loading && accesion_company.status == 'rechazada'"></span>Rechazar</b-button>
            <b-button @click="cancelForm()">Cancel</b-button>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>